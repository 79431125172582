import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { EditAndSidePeek } from "./EditAndSidePeek";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { MoreVert, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomTextBox";
import { useForm } from "react-hook-form";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";

const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

const payRunKeyPair = [
  {
    name: "Employee Name",
  },
  {
    name: "Employee Id",
  },
  {
    name: "Paid Days",
  },
  {
    name: "Gross Pay",
  },
  {
    name: "Deductions",
  },
  {
    name: "Taxes",
  },
  {
    name: "Reimbursements",
  },
  {
    name: "Net Pay",
  },
  {
    name: "Action",
  },
];

export const ConstStructure = ({
  viewPayrunDetails,
  setPayrollCost,
  setEmployeeNetPay,
  setTotalDeduction,
  setTotalWorkingHours,
  companyId,
  reloadUserPayrollDetails,
  setReloadUserPayrollDetails,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reason, setReason] = useState("");
  const [costStructureDetailsState, setCostStructureDetailsState] = useState(
    []
  );
  const [updateData, setUpdateData] = useState(true);
  const { result: payrunData, setLoad: reloadApi } = useFetch(
    `${domain}payrun/retrieve/?pay_schedule_id=${viewPayrunDetails.id}`
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (reloadUserPayrollDetails) {
      reloadApi(true);
      setReloadUserPayrollDetails(false);
    }
  }, [reloadApi, reloadUserPayrollDetails, setReloadUserPayrollDetails]);

  useEffect(() => {
    if (payrunData) {
      setPayrollCost(payrunData?.summary?.payroll_cost);
      setEmployeeNetPay(payrunData?.summary?.employee_net_pay);
      setTotalDeduction(payrunData?.summary?.total_deduction);
      setTotalWorkingHours(payrunData?.working_days);
    }
  }, [
    payrunData,
    setEmployeeNetPay,
    setPayrollCost,
    setTotalDeduction,
    setTotalWorkingHours,
  ]);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async () => {
    try {
      const response = await fetch(`${domain}payrun/skip-salary/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          payroll_run_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        reloadApi(true);
        showToast("error", res.message);
        setOpenDialog(false);
        setReason("");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateReasonForSkipSalary = async (event) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    if (
      updateData &&
      payrunData?.payroll_run_details?.length !== 0 &&
      payrunData
    ) {
      setCostStructureDetailsState(payrunData?.payroll_run_details);
      setUpdateData(false);
    }
  }, [payrunData, updateData]);

  useEffect(() => {
    if (payrunData?.length !== 0 || payrunData?.length === 0) {
      const filteredResults = payrunData?.payroll_run_details?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setCostStructureDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunData]);

  return (
    <React.Fragment>
      {costStructureDetailsState?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 2, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {payRunKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {costStructureDetailsState
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((user) => (
                  <Mui.TableRow sx={{ cursor: "pointer" }}>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      <Mui.Stack direction="Row">
                        <Mui.Avatar
                          sx={{ width: 25, height: 25 }}
                          alt="Remy Sharp"
                          src={user.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                          }}
                        >
                          {user.user_name}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {" "}
                      {user?.employee_id}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {user?.paid_days}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {user?.gross_pay}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {user?.total_deduction}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {"N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {"N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      onClick={() => {
                        setEditUserDetails(user);
                        setOpenSidePeek(true);
                      }}
                    >
                      {user?.net_pay}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setEditUserDetails(user);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setOpenDialog(true);
                          }}
                        >
                          Skip from this payroll
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                          }}
                        >
                          Revise Salary
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                          }}
                        >
                          View Employee Details
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {costStructureDetailsState?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={costStructureDetailsState?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
      {openSidePeek ? (
        <EditAndSidePeek
          openSidePeek={openSidePeek}
          setOpenSidePeek={setOpenSidePeek}
          editUserDetails={editUserDetails}
          companyId={companyId}
          reloadApi={reloadApi}
          viewPayrunDetails={viewPayrunDetails}
          payrunData={payrunData}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
          setReason("");
        }}
        open={openDialog}
        title="Skip Employee"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{ padding: 2, backgroundColor: themes.formBackground }}
            >
              <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
                <Info sx={{ color: themes.redColor, fontSize: 40 }} />
              </Mui.Grid>
              <Mui.Grid xs={10}>
                <Mui.Typography>
                  Once you skip an employee(s) from the pay run , you will not
                  be able to pay them later for this pay cycle
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Employee
                </Mui.Typography>
                <Mui.Grid container>
                  <Mui.Grid xs={3}>
                    <Mui.Avatar
                      sx={{ width: 40, height: 40 }}
                      alt="Remy Sharp"
                      src={editUserDetails.image_url}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={9}>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.user_name}
                    </Mui.Typography>
                    <Mui.Typography sx={{ color: themes.headLine }}>
                      {editUserDetails.employee_id}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={2}></Mui.Grid>
              <Mui.Grid xs={5}>
                <Mui.Typography sx={{ paddingBottom: 0.5 }}>
                  Payroll Period
                </Mui.Typography>
                <Mui.Typography>
                  {viewPayrunDetails.start_date +
                    "-" +
                    viewPayrunDetails.end_date}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <CustomTextBox
                type="multiline"
                selectedValue={reason}
                rules={{
                  ...register("skip_reason", {
                    required: false,
                  }),
                }}
                updateDataFunction={updateReasonForSkipSalary}
                placeHolder="Enter the reason"
                errorMessage={
                  errors?.skip_reason?.message
                    ? errors?.skip_reason?.message
                    : ""
                }
                textBoxError={errors.skip_reason ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setReason("");
                }}
                actionName="No"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton actionName="Yes" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
