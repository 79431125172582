import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
import { useFetch } from "services/hooks/useFetch";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import { overTimeKeyPair } from "pages/PayRoll/PayRollHeaders";
import { CustomTextBox } from "components/CustomTextBox";
import { Cancel, Done, Edit } from "@material-ui/icons";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
// import { rgxWithDot } from "services/constants/patterns";
import { onlyNumberRegex } from "services/constants/patterns";

const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

export const OverTime = ({ viewPayrunDetails }) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [newTotalLeave, setNewTotalLeave] = useState("");
  const [overTimeDetailsState, setOverTimeDetailsState] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  // const [updateData, setUpdateData] = useState(true);

  const { result: payrunData, loading } = useFetch(
    `${domain}payrun/retrieve/?pay_schedule_id=${viewPayrunDetails.id}`
  );
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleSave = async (userId, newTotalLeave) => {
    const totalLeave = parseFloat(newTotalLeave);
    try {
      const response = await fetch(`${domain}update-leave-count/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          // leave_type_id: shiftId,
          user_id: userId,
          total_leave: totalLeave,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        // setUpdateData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  const handleCancel = () => {
    setEditRowId(null);
  };

  const updateRatePerHour = (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      setNewTotalLeave(event.target.value);
    }
  };

  console.log(payrunData);

  useEffect(() => {
    if (
      updateData &&
      payrunData?.payroll_run_details?.length !== 0 &&
      payrunData
    ) {
      setOverTimeDetailsState(payrunData?.payroll_run_details);
      setUpdateData(false);
    }
  }, [payrunData, updateData]);

  useEffect(() => {
    if (payrunData?.length !== 0 || payrunData?.length === 0) {
      const filteredResults = payrunData?.payroll_run_details?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setOverTimeDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunData]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {overTimeDetailsState?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {overTimeKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {overTimeDetailsState
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((payschedule) => (
                  <Mui.TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      // setViewPayRunDetails(payschedule);
                      // setViewPayRunScreen(true);
                    }}
                  >
                    <Mui.TableCell>
                      <Mui.Stack direction="Row">
                        <Mui.Avatar
                          sx={{ width: 25, height: 25 }}
                          alt="Remy Sharp"
                          src={payschedule.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                          }}
                        >
                          {payschedule.user_name}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell>{payschedule?.employee_id}</Mui.TableCell>
                    <Mui.TableCell>{payschedule?.paid_days}</Mui.TableCell>
                    <Mui.TableCell>{payschedule?.gross_pay}</Mui.TableCell>
                    <Mui.TableCell>
                      {payschedule?.total_deduction}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {editRowId === payschedule.id ? (
                        <Mui.Grid sx={{ width: 130 }}>
                          <CustomTextBox
                            selectedValue={newTotalLeave}
                            updateDataFunction={updateRatePerHour}
                            placeHolder="Enter value"
                          />
                        </Mui.Grid>
                      ) : (
                        payschedule.total_leave
                      )}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {" "}
                      {editRowId === payschedule.id ? (
                        <Mui.Grid sx={{ width: 130 }}>
                          <CustomTextBox
                            selectedValue={newTotalLeave}
                            updateDataFunction={updateRatePerHour}
                            placeHolder="Enter value"
                          />
                        </Mui.Grid>
                      ) : (
                        payschedule.total_leave
                      )}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {editRowId === payschedule.id ? (
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          // value={
                          //   shiftData?.find(
                          //     (option) => option?.name === shiftName[i]
                          //   ) || ""
                          // }
                          // options={shiftData.filter(
                          //   (value) => value?.label !== "Weekoff"
                          // )}
                          // getOptionLabel={(option) =>
                          //   option?.name ?? shift?.shift_name ?? ""
                          // }
                          // isOptionEqualToValue={(option, value) =>
                          //   option.name === value.name
                          // }
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option && option.name
                                ? option.name
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                : false
                            );
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option.name}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              overflow: "auto",
                            },
                          }}
                          // onChange={async (event, value) => {
                          //   if (!value) {
                          //     setshiftId("");
                          //     setShiftName("");
                          //     handleShiftChange(i, "", "");
                          //   } else {
                          //     handleShiftChange(i, value?.name, value?.id);
                          //   }
                          // }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{
                                background: themes.whiteColor,
                                padding: 0.5,
                              }}
                              placeholder="Select Value"
                              size="small"
                            />
                          )}
                        />
                      ) : null}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.Grid item>
                        {editRowId === payschedule.id ? (
                          <>
                            <Mui.IconButton
                              onClick={() => {
                                handleSave(payschedule.id, newTotalLeave);
                                setEditRowId(null);
                              }}
                            >
                              <Done
                                sx={{
                                  color: themes.primaryIndicator,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                            <Mui.IconButton onClick={handleCancel}>
                              <Cancel
                                sx={{
                                  color: themes.redColor,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </>
                        ) : (
                          <Mui.Tooltip title="Edit" placement="right">
                            <Mui.IconButton
                              onClick={() => {
                                setEditRowId(payschedule.id);
                                setNewTotalLeave(payschedule.total_leave);
                              }}
                            >
                              <Edit
                                sx={{
                                  color: themes.primaryIndicator,
                                  strokeWidth: 2,
                                }}
                              />
                            </Mui.IconButton>
                          </Mui.Tooltip>
                        )}
                      </Mui.Grid>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {overTimeDetailsState?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={overTimeDetailsState?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>

      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
