import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Resizer from "react-image-file-resizer";
import { themes } from "services/constants";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { ProfilePopup } from "./ProfilePopup";
import { setGlobalSearchValue } from "services/Redux/userToken";
import {
  setEditUserDetailsApi,
  setProfileEditBack,
} from "services/Redux/userManagament";
import * as Mui from "@mui/material";
import { dateFormat } from "services/constants";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userManagementError } from "services/constants/ErrorMessages";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomButton";

export const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { editUserId, profileEdit, backToUserGroup, editUserDetailsApi } =
    useSelector((state) => state.userReducer);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [editUserData, setEditUserData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [profile, setProfile] = useState("");
  const [updatedProfile, setupdateProfile] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${domain}person/${editUserId}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setEditUserData(res);
          dispatch(setEditUserDetailsApi(false));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (editUserDetailsApi) {
      fetchData();
    }
  }, [dispatch, domain, editUserDetailsApi, editUserId, token]);

  const handleClose = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    const updateProfileFile = async () => {
      setButtonLoader(true);
      try {
        if (profile.size > 100000) {
          showToast("error", userManagementError.imagesizeError);
        } else {
          const myHeaders = new Headers();
          const formData = new FormData();
          myHeaders.append("Authorization", `token ${token}`);
          formData.append("file", profile, "[PROXY]");
          formData.append("user_id", editUserId);
          const response = await fetch(`${domain}avatars/`, {
            method: "PUT",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
          });
          const res = await response.json();
          if (response.ok) {
            setEditUserData([]);
            dispatch(setEditUserDetailsApi(true));
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    };
    if (updatedProfile) {
      updateProfileFile();
      setupdateProfile(false);
    }
  }, [
    dispatch,
    domain,
    editUserId,
    profile,
    profileEdit,
    token,
    updatedProfile,
  ]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        128,
        128,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const navigateToCompanyGroup = () => {
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/view/user/groups");
    dispatch(setProfileEditBack(false));
  };

  return (
    <React.Fragment>
      {profileEdit ? (
        <Mui.Button
          sx={{ marginTop: 2, paddingBottom: 2 }}
          onClick={() => {
            backToUserGroup
              ? navigateToCompanyGroup()
              : history.push("/admin/user");
          }}
        >
          Back
        </Mui.Button>
      ) : (
        <Mui.Grid container sx={{ marginTop: 2, paddingBottom: 2 }}></Mui.Grid>
      )}
      <Mui.Grid container spacing={2} sx={{ padding: 1 }}>
        <Mui.Box sx={{ boxShadow: 3, width: "100%", height: "auto" }}>
          <Mui.Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "2px solid Black",
            }}
          >
            <Mui.Grid container sx={{ height: "auto", display: "flex" }}>
              <Mui.Grid
                xs={3}
                sx={{ paddingTop: 2, paddingLeft: 4, alignContent: "center" }}
              >
                <Mui.Avatar
                  sx={{
                    width: 160,
                    height: 160,
                    fontSize: 60,
                  }}
                  alt={editUserData?.full_name?.charAt(0).toUpperCase()}
                  src={editUserData?.image_url}
                />
                <Mui.Grid
                  sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}
                >
                  {buttonLoader ? (
                    <CustomButton
                      actionName="Change Profile"
                      typeName="submit"
                      disableAction={buttonLoader}
                    />
                  ) : (
                    <Mui.Button
                      variant="contained"
                      component="label"
                      sx={{
                        alignItems: "center",
                        background: themes.primaryButton,
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: themes.primaryButton,
                        },
                      }}
                    >
                      <Mui.Typography sx={
                        {
                          color:themes.blackColor
                        }
                      }>Change Profile</Mui.Typography>
                      <Mui.TextField
                        style={{ display: "none", width: 300, height: 300 }}
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          resizeFile(e.target.files[0]);
                          setProfile(e.target.files[0]);
                          setupdateProfile(true);
                        }}
                      />
                    </Mui.Button>
                  )}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                xs={3.5}
                sx={{
                  paddingTop: 3,
                  borderRight: "2px solid Black",
                  height: "auto",
                }}
                spacing={2}
              >
                <Mui.Typography
                  sx={{
                    fontSize: 30,
                    color: themes.blackColor,
                    paddingBottom: 0.5,
                  }}
                >
                  {editUserData?.full_name}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.blackColor,
                    paddingBottom: 0.5,
                  }}
                >
                  {editUserData?.role}
                </Mui.Typography>
                <br />
                <Mui.Stack direction="row">
                  <Mui.Grid xs={5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      User Code
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      :
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.Typography>{editUserData?.user_code}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Stack>
                <Mui.Stack direction="row">
                  <Mui.Grid xs={5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      Date of Joining
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      :
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.Typography>
                      {editUserData?.date_of_join}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Stack>
                <Mui.Stack direction="row">
                  <Mui.Grid xs={5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      Department Name
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      :
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.Typography>
                      {editUserData?.team ? editUserData?.team : "N/A"}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Stack>
                <Mui.Stack direction="row">
                  <Mui.Grid xs={5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      Department Lead
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}>
                    <Mui.Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: 15,
                        color: themes.blackColor,
                        paddingBottom: 0.5,
                      }}
                    >
                      :
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={8}>
                    <Mui.Typography>
                      {editUserData?.department_lead_name}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid
                xs={5.5}
                sx={{ paddingTop: 2, paddingLeft: 2 }}
                spacing={2}
              >
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>Phone</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Mui.Stack direction="Row">
                      <Mui.Grid container justify="flex-start">
                        <Mui.Typography>
                          {editUserData?.phone_number}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid xs={1}>
                    <Mui.Grid container alignContent="flex-end">
                      <IconButton
                        sx={{ marginTop: -2.5 }}
                        onClick={() => {
                          setUpdateInfo(true);
                          setOpenPopup(true);
                        }}
                      >
                        <BorderColorSharpIcon />
                      </IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>Email</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography> {editUserData?.email}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>
                      Birthday
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography>
                      {editUserData?.birthday
                        ? moment(editUserData?.birthday).format(dateFormat)
                        : ""}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>Gender</Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography>{editUserData?.gender}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>
                      Aadhar Number{" "}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography>{editUserData?.aadhar}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>
                      Pan Number{" "}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography> {editUserData?.pan}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid xs={4} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography fontWeight={"bold"}>
                      Address{" "}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ fontWeight: "bold" }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={7}>
                    <Mui.Typography>{editUserData?.address}</Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.Box>
      </Mui.Grid>
      <ProfilePopup
        handleClose={handleClose}
        open={openPopup}
        title="Profile Information"
        editUserData={editUserData}
        updateInfo={updateInfo}
        setUpdateInfo={setUpdateInfo}
        buttonLoader={buttonLoader}
        setButtonLoader={setButtonLoader}
      />
    </React.Fragment>
  );
};
