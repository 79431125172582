import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { showToast } from "components/Status";
import PersonIcon from "@mui/icons-material/Person";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { CustomButton } from "components/CustomButton";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Loader } from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackArrow } from "components/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";
export const CreateUser = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMailTextBox, setOpenMailTextBox] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const response = await fetch(`${domain}get-active-company/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const companyDetailsWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.companyname,
          }));
          setCompanyDetails(companyDetailsWithLabel);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getCompany();
  }, [domain, token]);

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(
        data.sendMail
          ? `${domain}send-registration-email/`
          : `${domain}normal_user_registration/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            gender: data.gender,
            aadhar: data.aadhar,
            pan: data.panNumber ? data.panNumber.toUpperCase() : data.panNumber,
            address: data.address,
            phone_number: data.phoneno,
            user_name: data.name,
            email: data.email,
            role: "User",
            designation: data.designationId,
            employment_type_id: data.employmentTypeId,
            send_email: data.sendMail,
            company_id: data.companyid,
            department_id: data.departmentId,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        reset();
        showToast("success", "New user created successfully");
        setLoading(false);
        dispatch(setGlobalSearchValue(""));
        history.push("/admin/user");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const handlePreventCopyPaste = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow actionFunction={() => history.push("/admin/user")} />
        &nbsp;
        <Mui.Typography
          color={themes.headLine}
          sx={{
            paddingTop: 2,
            fontWeight: "bold",
            paddingBottom: 3,
            fontFamily: "Popins",
            fontSize: { xs: 20, sm: 30, md: 42, lg: 45 },
            paddingLeft: { xs: 3, sm: 3 },
          }}
        >
          Create new user
        </Mui.Typography>
      </Mui.Grid>
      {loading === true ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
              <Mui.Grid xs={10} sm={10} md={8} lg={6}>
                <Mui.TextField
                  label={
                    <Mui.Typography>
                      Name <span style={{ color: "red", fontSize: 20 }}>*</span>
                    </Mui.Typography>
                  }
                  {...register("name", {
                    required: "Name is required",
                    maxLength: {
                      value: 30,
                      message: "Maximum length is 30 characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "Name must contain at least one alphabet character.";
                        }
                        if (/^\d+$/.test(fieldValue)) {
                          return "Name should not contain only digits.";
                        }
                        if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                          return "Name should only contain alphabets, spaces, -,numbers and & symbol.";
                        }
                        return true;
                      },
                      emailAvailable: async (fieldValue) => {
                        if (fieldValue !== "") {
                          try {
                            const response = await fetch(
                              `${domain}check_username/?username=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "content-type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const res = await response.json();
                            if (response.ok) {
                              if (res.exists) {
                                return "Name Already Exists";
                              } else {
                                return true;
                              }
                            } else if (response.status === 409) {
                              sessionHandling();
                            } else {
                              throw new Error(res.error);
                            }
                          } catch (error) {
                            showToast("error", error.message);
                          }
                        }
                      },
                    },
                  })}
                  name="name"
                  error={errors.name ? true : false}
                  helperText={errors.name ? errors.name.message : null}
                  sx={{ background: "white" }}
                  placeholder="Enter your name"
                  onKeyPress={checkSpecialChar}
                  InputProps={{
                    startAdornment: (
                      <>
                        <PersonIcon
                          style={{
                            margin: "0 8px",
                            color: themes.allIconColor,
                          }}
                        />
                      </>
                    ),
                    inputProps: { maxLength: 30 },
                  }}
                  onBlur={() => trigger("name")}
                  onPaste={handlePreventCopyPaste}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 3 }}>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
              <Mui.Grid xs={10} sm={10} md={8} lg={6}>
                <Mui.TextField
                  label={
                    <Mui.Typography>
                      Phone No
                      <span style={{ color: "red", fontSize: 20 }}>*</span>
                    </Mui.Typography>
                  }
                  {...register("phoneno", {
                    required: "Phone No is required",
                    minLength: {
                      value: 10,
                      message: "Minimum length is 10 digits",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum length is 15 digits",
                    },
                  })}
                  InputProps={{
                    inputProps: { maxLength: 15 },
                    startAdornment: (
                      <>
                        <PhoneIcon
                          style={{
                            margin: "0 8px",
                            color: themes.allIconColor,
                          }}
                        />
                      </>
                    ),
                  }}
                  sx={{ background: "white" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter your phone number"
                  error={errors.phoneno ? true : false}
                  helperText={errors.phoneno ? errors.phoneno.message : null}
                  onPaste={handlePreventCopyPaste}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 3 }}>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
              <Mui.Grid xs={10} sm={10} md={8} lg={6}>
                <Mui.Autocomplete
                  id="combo-box-demo"
                  options={companyDetails}
                  ListboxProps={{ style: { maxHeight: "150px" } }}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.label
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.label}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setValue("companyid", value.id);
                    } else {
                      setValue("companyid", null);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      label={
                        <Mui.Typography>
                          Company{" "}
                          <span style={{ color: "red", fontSize: 20 }}>*</span>
                        </Mui.Typography>
                      }
                      {...register("company", {
                        required: "Company name is required",
                      })}
                      sx={{ background: "white" }}
                      placeholder="Select a company"
                      error={errors.company ? true : false}
                      helperText={
                        errors.company ? errors.company.message : null
                      }
                      InputProps={{
                        ...params.InputProps,

                        startAdornment: (
                          <>
                            <CorporateFareOutlinedIcon
                              style={{
                                margin: "0 8px",
                                color: themes.allIconColor,
                              }}
                            />{" "}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
              <Mui.Grid xs={10} sm={10} md={8} lg={6}>
                <Mui.Stack direction="row">
                  <Mui.Checkbox
                    onChange={(event) => {
                      setValue("sendMail", event.target.checked);
                      setOpenMailTextBox(event.target.checked);
                      if (!event.target.checked) {
                        setValue("email", "");
                      }
                    }}
                  />
                  <Mui.Typography
                    sx={{
                      paddingTop: { xs: 1.5, sm: 1, md: 1, lg: 1 },
                      fontSize: { xs: 13, sm: 16, md: 18, lg: 18 },
                      color: "#616977",
                    }}
                  >
                    Send invite via email to create login
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
            </Mui.Grid>
            {openMailTextBox ? (
              <Mui.Grid container sx={{ paddingTop: 3 }}>
                <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
                <Mui.Grid xs={10} sm={10} md={8} lg={6}>
                  <Mui.TextField
                    label="Email"
                    {...register("email", {
                      required: openMailTextBox
                        ? "Email address is required"
                        : openMailTextBox,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid e-mail address",
                      },
                      validate: {
                        emailAvailable: async (fieldValue) => {
                          if (fieldValue !== "") {
                            try {
                              const response = await fetch(
                                `${domain}email_verfication/?email=${fieldValue}`
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Email Already Exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        },
                      },
                    })}
                    InputProps={{
                      startAdornment: (
                        <>
                          <EmailIcon
                            style={{
                              margin: "0 8px",
                              color: themes.allIconColor,
                            }}
                          />
                        </>
                      ),
                    }}
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email.message : null}
                    sx={{ background: "white" }}
                    placeholder="Enter your email"
                    fullWidth
                  />
                </Mui.Grid>
                <Mui.Grid xs={1} sm={1} md={2} lg={3}></Mui.Grid>
              </Mui.Grid>
            ) : null}
            <Mui.Grid
              container
              sx={{
                paddingTop: { xs: 4, sm: 4, md: 4, lg: 10 },
                paddingBottom: 2,
              }}
            >
              <Mui.Grid xs={1} sm={1} md={8.3} lg={8.3}></Mui.Grid>
              <Mui.Grid xs={10} sm={10} md={2.7} lg={2.7}>
                <CustomButton
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Grid>
              <Mui.Grid xs={1} sm={1} md={1} lg={1}></Mui.Grid>
            </Mui.Grid>
          </form>
        </>
      )}
    </React.Fragment>
  );
};
