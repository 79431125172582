import React, { useState, useEffect } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { earningHeaders } from "pages/PayRoll/PayRollHeaders";
import { MoreVert } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomTextBox";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Loader } from "components/Loader";
import { atleastOneCharacterWithSpace} from "services/constants/patterns";
const keys = ["name"];

export const Earning = ({
  companyId,
  selectedComponent,
  reloadData,
  setReloadData,
  currentScreen,
  companyChange,
  setCompanyChange,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [editEarning, setEditEarning] = useState(null);
  const [earningDetails, setEarningDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);

  const {
    result: earningData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );

  useEffect(() => {
    if (reloadData && selectedComponent === "Earning") {
      reCallApi(true);
      setReloadData(false);
    }
  });

  useEffect(() => {
    if (
      companyChange === true &&
      currentScreen === "Salary Component" &&
      selectedComponent === "Earning"
    ) {
      reCallApi(true);
      setCompanyChange(false);
    }
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    selectedComponent,
    setCompanyChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${domain}earnings/${editEarning.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          name: data.component_name,
          company: companyId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setOpenDialog(false);
        handleCloseDialogAndDetails();
        showToast("success", res.message);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateComponentName = (event) => {
    if (event.target.value.length <= 64) {
      setComponentName(event.target.value);
      setValue("component_name", event.target.value);
    }
  };

  const handleCloseDialogAndDetails = () => {
    setOpenDialog(false);
    setTimeout(function () {
      setComponentName("");
      reset();
    }, 100);
  };

  useEffect(() => {
    if (updateData && earningData?.length !== 0 && earningData) {
      setEarningDetails(earningData);
      setUpdateData(false);
    }
  }, [earningData, updateData]);

  useEffect(() => {
    if (earningData?.length !== 0 || earningData?.length === 0) {
      const filteredResults = earningData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setEarningDetails(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, earningData]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        {earningDetails?.length === 0 ? (
          <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
            No data found
          </Mui.Alert>
        ) : (
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  {earningHeaders.map((data) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {data?.header}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {earningDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((earning, index) => (
                    <React.Fragment>
                      <Mui.TableRow>
                        {earningHeaders.map((data) =>
                          data.header !== "Action" ? (
                            <Mui.TableCell>{earning[data.key]}</Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={(e) => {
                                  setComponentName(earning.name);
                                  setValue("component_name", earning.name);
                                  setAnchorEl(e.currentTarget);
                                  setEditEarning(earning);
                                }}
                              >
                                <MoreVert
                                  sx={{
                                    color: themes.blackColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setAnchorEl(null);
                                  }}
                                >
                                  Edit
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        )}
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => handleCloseDialogAndDetails()}
        open={openDialog}
        title="Edit Earning"
        minWidth={600}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Name</Mui.Typography>
            <CustomTextBox
              selectedValue={componentName}
              rules={{
                ...register("component_name", {
                  required: "Component name is required",
                  minLength: {
                    value: 3,
                    message: "Minium length is 3 characters",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 3 && value !== "") {
                        return minimum3Characters;
                      } else {
                        return true;
                      }
                    },
                    uniqueNameCheck: async (value) => {
                      if (editEarning.name !== value) {
                        const response = await fetch(
                          `${domain}earning/unique`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `token ${token}`,
                            },
                            body: JSON.stringify({
                              company: companyId,
                              name: value.trim(),
                            }),
                          }
                        );
                        const res = await response.json();
                        if (res.exists) {
                          return "Earning name already exists";
                        }
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateComponentName}
              placeHolder="Enter the component name"
              errorMessage={
                errors?.component_name?.message
                  ? errors?.component_name?.message
                  : ""
              }
              textBoxError={errors.component_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setTimeout(function () {
                    setComponentName("");
                    reset();
                  }, 100);
                }}
                actionName="Cancel"
                typeName="button"
              />{" "}
              &nbsp;
              <CustomButton actionName="Update" typeName="submit" />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {earningDetails.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={earningDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
