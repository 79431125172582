import React, { useState, useEffect, useRef } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import EmailDialog from "components/DialogBox/SystemAccess";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { Loader } from "components/Loader";
import { userHeader } from "./AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";

export const ListView = ({
  openRevokeDialog,
  setOpenRevokeDialog,
  openEmail,
  setOpenEmail,
  checkExistingUser,
  inviteSystemAccess,
  revokeSystemAccess,
  isLoading,
  allUsers,
  dropDown,
  updateMenuItem,
  setEditUserDetails,
  editUser,
  UpdateUserDetails,
  anchorEl,
  setAnchorEl,
  filterChanges,
  setFilterChanges,
  searchedValue,
  buttonLoader,
}) => {
  const userDetails = useSelector((state) => state.tokenReducer.userDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    scrollTop(tableContainerRef);
    const handleDialog = () => {
      setOpenRevokeDialog(false);
      setOpenEmail(false);
    };
    handleDialog();
  }, [
    filterChanges,
    setFilterChanges,
    setOpenEmail,
    setOpenRevokeDialog,
    page,
  ]);

  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setPage(0);
    }
  }, [filterChanges, setFilterChanges]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialogBox = () => {
    setOpenRevokeDialog(false);
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openRevokeDialog}
        title="Revoke System Access"
      >
        <Mui.Grid container>
          <Mui.Grid item xs={12}>
            <Mui.Typography fontSize={22}>
              Are you sure you want to revoke system access for{" "}
              <b style={{ color: themes.redColor }}>{editUser?.user_name}</b>
              &nbsp;user?
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleCloseDialogBox}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={() =>
                revokeSystemAccess(editUser?.id, editUser?.email)
              }
              actionName="Yes"
              typeName="submit"
              disableAction={
                openRevokeDialog && buttonLoader ? buttonLoader : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <EmailDialog
        openDialog={openEmail}
        setOpenDialog={setOpenEmail}
        checkExistingUser={checkExistingUser}
        inviteSystemAccess={inviteSystemAccess}
        editUser={editUser}
        buttonLoader={buttonLoader}
      />
      {isLoading ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : allUsers?.length === 0 ? (
        <Mui.Box textAlign="center" my={2}>
          <Mui.Alert severity="info">
            {searchedValue !== "" ? "No data available" : "No data available"}
          </Mui.Alert>
        </Mui.Box>
      ) : (
        <Mui.Grid container sx={{ paddingRight: 1, overflow: "hidden" }}>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={tableContainerSx}
            ref={tableContainerRef}
          >
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {userHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {allUsers
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data, index) => (
                    <Mui.TableRow key={data?.id} sx={tableRowSx}>
                      <>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.Stack direction="Row">
                            <Mui.Avatar
                              sx={{ width: 25, height: 25 }}
                              alt="Remy Sharp"
                              src={data?.image_url}
                            />
                            <Mui.Typography
                              sx={{
                                paddingLeft: 1,
                                fontSize: tableCellFontSize,
                              }}
                            >
                              {data?.user_name ? data?.user_name : "N/A"}
                            </Mui.Typography>
                          </Mui.Stack>
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.user_code ? data?.user_code : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.designation ? data?.designation : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.employment_type
                            ? data?.employment_type
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.department ? data?.department : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.department_lead_name
                            ? data?.department_lead_name
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.company_name ? data?.company_name : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.system_access ? data?.system_access : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {data?.status ? data?.status : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          <Mui.IconButton
                            onClick={(e) => {
                              if (data.role !== "Admin") {
                                setEditUserDetails(data);
                                updateMenuItem(
                                  data?.status,
                                  data?.role,
                                  data?.system_access
                                );
                                handleMenuOpen(e, index);
                              } else if (userDetails.role === "Admin") {
                                setEditUserDetails(data);
                                updateMenuItem(
                                  data?.status,
                                  data?.role,
                                  data?.system_access
                                );
                                handleMenuOpen(e, index);
                              }
                            }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {dropDown.map((option) =>
                              option.dropMenu !== "Make TeamLead" ? (
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={option.dropMenu}
                                    onClick={() => {
                                      UpdateUserDetails(option.dropMenu);
                                      handleMenuClose();
                                    }}
                                  >
                                    {option.dropMenu === "Inactive"
                                      ? "Inactivate"
                                      : option.dropMenu === "Active"
                                      ? "Activate"
                                      : option.dropMenu}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              ) : editUser.role === "User" ||
                                editUser.role === "Admin" ? (
                                <Mui.Stack>
                                  <Mui.MenuItem
                                    key={option.dropMenu}
                                    onClick={() =>
                                      UpdateUserDetails(option.dropMenu)
                                    }
                                  >
                                    {option.dropMenu}
                                  </Mui.MenuItem>
                                </Mui.Stack>
                              ) : null
                            )}
                          </Mui.Menu>
                        </Mui.TableCell>
                      </>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Grid>
      )}
      {allUsers?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={allUsers?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
