import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Pending from "./Pending";
import Approved from "./Approved";
import LeavesCard from "./LeaveRequest";
import { showToast } from "components/Status";
import { themes, defaultAllSelection } from "services/constants";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import * as Mui from "@mui/material";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import sessionHandling from "services/utils/notificationUtils";
import { HrmsPermission } from "./HrmsPermission";
export const Permissions = ({ setReloadData }) => {
  const dispatch = useDispatch();
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedComponent, setSelectedComponent] = React.useState("pending");
  const [pendingdata, setPendingData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [updateLeaveCard, setUpdateLeaveCard] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [reloadApprovedData, setReloadApprovedData] = useState(false);
  const [reloadRejectedData, setReloadRejectedData] = useState(false);
  const [alignment, setAlignment] = useState("Approve");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentData([]);
          const departmentDataWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          const allDepartment = {
            id: defaultAllSelection,
            label: "All",
            departmentname: "All",
          };
          setDepartmentData([allDepartment, ...departmentDataWithLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const permissionRequestApi = `${domain}permisson_request/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}`;
        const response2 = await fetch(permissionRequestApi, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res2 = await response2.json();
        if (response2.ok) {
          const totalData = res2;
          totalData?.sort(
            (a, b) =>
              new Date(b?.from_date.split("-").reverse().join("-")) -
              new Date(a?.from_date.split("-").reverse().join("-"))
          );
          setPendingData(totalData);
          setMasterDataForSearch(totalData);
        } else {
          if (!response2.ok) {
            if (response2.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res2.error);
            }
          }
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (selectedComponent === "pending") {
      fetchData();
    }
  }, [
    departmentId,
    domain,
    selectedComponent,
    token,
    userDetails.id,
    userDetails.role,
  ]);

  const handleApproveClick = (item) => {
    const { id } = item;
    setPendingData((prevData) => {
      const updatedData = prevData.filter((item) => item.id !== id);
      return updatedData;
    });
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "approved":
        return (
          <Approved
            setReloadApprovedData={setReloadApprovedData}
            reloadApprovedData={reloadApprovedData}
          />
        );
      case "rejected":
        return (
          <HrmsPermission
            setReloadRejectedData={setReloadRejectedData}
            reloadRejectedData={reloadRejectedData}
          />
        );
      case "pending":
        return (
          <Pending
            data={pendingdata}
            loading={loading}
            onApproveClick={handleApproveClick}
            setUpdateLeaveCard={setUpdateLeaveCard}
            setReloadData={setReloadData}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch?.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toString().toLowerCase()) > -1
            : null
        )
      );
    };
    setPendingData(FilterDockets());
    // setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  // const items = [
  //   {
  //     count: "0",
  //     text: "Pending Approval",
  //     value: "pending",
  //     countColor: themes.lightGreen,
  //   },
  //   {
  //     count: "0",
  //     text: "Approved",
  //     value: "approved",
  //     countColor: themes.redColor,
  //   },
  //   {
  //     count: "0",
  //     text: "Rejected",
  //     value: "rejected",
  //     countColor: themes.redColor,
  //   },
  // ];

  return (
    <div className="flex flex-col items-center justify-center">
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
            height: 55,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "30px",
              backgroundColor: themes.lightBrown,
            }}
          >
            <ToggleButton
              value="Approve"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  alignment === "Approve"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  alignment === "Approve"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    alignment === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    alignment === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                setUpdateLeaveCard(true);
                setSelectedComponent("pending");
                setReloadData(true);
              }}
            >
              Approve
            </ToggleButton>
            <ToggleButton
              value="History"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                fontWeight: 600,
                border: "none",
                color:
                  alignment === "History"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  alignment === "History"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    alignment === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    alignment === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                setSelectedComponent("approved");
                setReloadApprovedData(true);
                setReloadData(true);
              }}
            >
              History
            </ToggleButton>
            <ToggleButton
              value="Apply Leave"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  alignment === "Apply Leave"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  alignment === "Apply Leave"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    alignment === "Apply Leave"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    alignment === "Apply Leave"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
                "&:focus": {
                  backgroundColor:
                    alignment === "Apply Leave"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    alignment === "Apply Leave"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
              onClick={() => {
                setReloadRejectedData(true);
                setSelectedComponent("rejected");
              }}
            >
              Apply
            </ToggleButton>
          </ToggleButtonGroup>
        </Mui.Box>
        <Mui.Grid xs={1}></Mui.Grid>
        {selectedComponent === "pending" ? (
          <Mui.Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1.5,
              paddingBottom: 1,
            }}
          >
            <Mui.Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              style={{
                width: 250,
              }}
              value={
                departmentData?.find(
                  (option) => option?.label === departmentName
                ) || departmentName
              }
              options={departmentData}
              getOptionLabel={(option) => (option?.label ? option?.label : "")}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option?.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                },
              }}
              onChange={async (event, value) => {
                if (value?.label) {
                  dispatch(setDepartmentName(value.label));
                  dispatch(setDepartmentId(value.id));
                  localStorage.setItem("approveLeaveDepartmentId", value.id);
                  localStorage.setItem(
                    "approveLeaveDepartmentName",
                    value.label
                  );
                  if (selectedComponent === "approved") {
                    setReloadApprovedData(true);
                  } else if (selectedComponent === "rejected") {
                    setReloadRejectedData(true);
                  } else if (selectedComponent === "pending") {
                    setUpdateLeaveCard(true);
                  }
                  setReloadData(true);
                }
              }}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Select department name"
                  label="Select Department Name"
                />
              )}
            />
          </Mui.Grid>
        ) : null}
      </Mui.Grid>

      {/* <Mui.Card
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: 20,
          width: "98%",
          justifyContent: "space-evenly",
          marginLeft: 20,
          marginRight: 20,
          borderColor: themes.primary,
          borderWidth: "2px",
          borderStyle: "solid",
        }}
      >
        {items.map((items, index) => (
          <Mui.Card
            key={index}
            style={{
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              height: 50,
              fontSize: 20,
              fontWeight: "bold",
              borderRadius: 0,
              color:
                selectedComponent === items?.value
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                selectedComponent === items?.value
                  ? themes.primaryIndicator
                  : themes.whiteColor,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              handleButtonClick(items.value);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            {items.text}
          </Mui.Card>
        ))}
      </Mui.Card> */}

      <Grid container>
        <Grid
          xs={selectedComponent === "pending" ? 8.8 : 12}
          sx={{ justifyContent: "center" }}
        >
          {renderComponent()}
        </Grid>
        <Grid
          xs={selectedComponent === "pending" ? 0.2 : 0}
          sx={{ borderRight: "4px solid black" }}
        ></Grid>
        <Grid xs={selectedComponent === "pending" ? 3 : 0}>
          {selectedComponent === "pending" ? (
            <LeavesCard
              updateLeaveCard={updateLeaveCard}
              setUpdateLeaveCard={setUpdateLeaveCard}
              departmentId={departmentId}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
