import React, { useState } from "react";
import * as Mui from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
const payScheduleKeyPair = [
  {
    key: "name",
    name: "Payrun name",
  },
  {
    key: `${"start_date"}-${"end_date"}`,
    name: "Payrun period",
  },
  {
    key: "run_date",
    name: "Pay date",
  },
  {
    key: "action",
    name: "Action",
  },
];

export const PayScheduleTable = ({
  payrunDetails,
  setEditPaySchedule,
  editPayScheduleDetails,
  setEditPayScheduleDetails,
  openDialog,
  setOpenDialog,
  updateDeletePayrun,
  listViewPage,
  setListViewPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      {payrunDetails?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 2, width: "100%", paddingRight: 1, maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {payScheduleKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {payrunDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((department) => (
                  <Mui.TableRow>
                    {payScheduleKeyPair.map((header) =>
                      header.name !== "Action" &&
                      header.name !== "Payrun period" ? (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {department[header.key]
                            ? department[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ) : header.name === "Action" ? (
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setEditPayScheduleDetails(department);
                            }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setEditPaySchedule(true);
                              }}
                            >
                              Edit
                            </Mui.MenuItem>
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenDialog(true);
                              }}
                            >
                              Delete
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          {department.start_date}-{department.end_date}
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <GlobalDialogBox
        handleCloseDialog={() => setOpenDialog(false)}
        open={openDialog}
        title="Delete Pay Schedule"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {editPayScheduleDetails?.name}
          </span>{" "}
          ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeletePayrun()}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      {payrunDetails.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={payrunDetails.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
