import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import { useSelector, useDispatch } from "react-redux";
import { dateFormat } from "services/constants";
import { setEditUserDetailsApi } from "services/Redux/userManagament";
import PropTypes from "prop-types";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import ManIcon from "@mui/icons-material/Man";
import { gender } from "services/constants";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  Close,
  AddRoad,
  CreditCard,
  Groups,
  LocalPhone,
  PushPin,
  Person,
  CalendarMonthSharp,
  Lan,
  AddLocationAlt,
  ContactEmergency,
  FlagCircle,
} from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const ProfilePopup = ({
  handleClose,
  open,
  title,
  editUserData,
  updateInfo,
  setUpdateInfo,
  buttonLoader,
  setButtonLoader,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [departmentName, setDepartmentName] = useState("");
  const [countryStatesList, setCountryStatesList] = useState([]);
  const [currentUserGender, setCurrentUserGender] = useState("");
  const [currentUserCountry, setCurrentUserCountry] = useState("");
  const [currentUserState, setCurrentUserState] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerId, setManagerId] = useState(null);
  const [birthDate, setBirthDate] = useState();
  const [panNumber, setPanNumber] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [dateValidation, setDateValidation] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [getDepartmentApi, setGetDepartmentApi] = useState(true);

  useEffect(() => {
    if (editUserData.length !== 0 && updateInfo === true) {
      setValue("aadharNumber", editUserData.aadhar);
      setValue("birthDate", editUserData.birthday);
      setBirthDate(editUserData.birthday);
      setValue("country", editUserData.country);
      setCurrentUserCountry(editUserData.country);
      setValue("department", editUserData.department_name);
      setValue("firstName", editUserData.first_name);
      setValue("gender", editUserData.gender);
      setCurrentUserGender(editUserData.gender);
      setValue("lastName", editUserData.last_name);
      setValue("panNumber", editUserData.pan);
      setPanNumber(editUserData.pan);
      setValue("address", editUserData.address);
      setValue("phoneNumber", editUserData.phone_number);
      setValue("pinCode", editUserData.pincode);
      setValue("state", editUserData.state);
      setCurrentUserState(editUserData.state);
      setValue("teamLead", editUserData.team_lead_name);
      setManagerName(editUserData.team_lead_name);
      setDepartmentName(editUserData.department_name);
      setUpdateInfo(false);
    }
  }, [editUserData, setUpdateInfo, setValue, updateInfo]);

  const handleDateChange = (date) => {
    if (date) {
      setBirthDate(new Date(date).toISOString().substring(0, 10));
      if (
        moment(new Date(date)).format(dateFormat) !==
        moment(new Date()).format(dateFormat)
      ) {
        if (new Date(date) < new Date()) {
          setDateValidation("");
        } else {
          setDateValidation("Future date is not allowed for birthday");
        }
      } else {
        setDateValidation("Current date is not allowed for birthday");
      }
    }
  };

  useEffect(() => {
    const getCountryAndState = async () => {
      try {
        const response = await fetch(`${domain}get_countries/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });

        const res = await response.json();
        if (response.ok) {
          const allCountryDetails = res.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          setAllCountries(allCountryDetails);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getCountryAndState();
  }, [domain, token]);

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const response = await fetch(`${domain}department/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allDepartments = res.map((obj) => ({
            ...obj,
            label: obj.department,
          }));
          if (userDetails.role !== "User") {
            const createButton = {
              id: 9998,
              department: "Default Department",
              label: "Default Department",
            };
            setDepartmentList([...allDepartments, createButton]);
          } else {
            setDepartmentList(allDepartments);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    const getTeamLeadFilter = async () => {
      try {
        const response = await fetch(`${domain}departmentleadfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const managerListData = res.map((obj) => ({
            ...obj,
            label: obj.user?.auth_user.username,
            username: obj.user?.auth_user.username,
          }));
          let removeEmptyTeamTeamLead = managerListData.filter(
            (data) => data.user?.team?.length !== 0
          );
          setManagerList(removeEmptyTeamTeamLead);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getDepartmentApi) {
      getDepartment();
      getTeamLeadFilter();
      setGetDepartmentApi(false);
    }
  }, [domain, getDepartmentApi, token, userDetails.role]);

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const updateUserProfile = async (data) => {
    setButtonLoader(true);
    try {
      if (dateValidation === "") {
        const response = await fetch(
          `${domain}personal_details/${editUserData.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              dob: birthDate,
              gender: data.gender,
              address: data.address,
              country: data.country,
              state: data.state,
              phone_number: data.phoneNumber,
              pincode: parseInt(data.pinCode),
              pan: data.panNumber.toUpperCase(),
              aadhar: data.aadharNumber,
              team_lead_id: managerId,
              first_name: data.firstName,
              last_name: data.lastName,
              user: editUserData.user_id,
              rate: data.rate,
              annual_leaves: data.annual_leaves,
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          showToast("success", "Status has been updated successfully");
          reset();
          handleClose();
          dispatch(setEditUserDetailsApi(true));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const handlePanChange = (e) => {
    const inputValue = e.target.value;
    setPanNumber(inputValue);
  };

  const getCountryStates = async (countryId) => {
    try {
      const response = await fetch(
        `${domain}get_states/?country_id=${countryId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const allCountryDetails = res.map((obj) => ({
          ...obj,
          label: obj.name,
        }));
        setCountryStatesList(allCountryDetails);
        setCurrentUserState("");
        setValue("state", "");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <div sx={{ minWidth: 550 }}>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <FuncDialogTitle
          id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: 550,
            color: themes.blackColor,
            fontWeight: 1000,
            fontSize: "23px",
          }}
          onClose={handleClose}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <Mui.DialogContent dividers>
          <form onSubmit={handleSubmit(updateUserProfile)}>
            <Mui.Grid conatiner>
              <Mui.Stack
                direction="Row"
                alignItems="center"
                justifyContent="center"
              >
                <Mui.Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: "#90c6f0",
                  }}
                  alt={editUserData.first_name?.charAt(0).toUpperCase()}
                  src={editUserData.image_url}
                />
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={12} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("firstName", {
                    required: "Minimum length is 3 characters",
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 characters",
                    },
                    maxLength: {
                      value: 30,
                      message: "Maximum length is 30 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "First name must contain at least one alphabet character.";
                        }
                        if (/^\d+$/.test(fieldValue)) {
                          return "First name should not contain only digits.";
                        }
                        if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                          return "First name should only contain alphabets, spaces, -,numbers and & symbol.";
                        }
                        return true;
                      },
                    },
                  })}
                  name="firstName"
                  sx={{ background: themes.whiteColor }}
                  placeholder="Enter your first name"
                  error={errors.firstName ? true : false}
                  helperText={errors.firstName?.message}
                  InputProps={{
                    startAdornment: (
                      <>
                        <Person
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </Mui.Grid>
              {/* <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Last Name
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("lastName", {
                    required: "Minimum length is 1 characters",
                    minLength: {
                      value: 1,
                      message: "Minimum length is 1 characters",
                    },
                    maxLength: {
                      value: 30,
                      message: "Maximum length is 30 characters",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        if (!/[A-Za-z]/.test(fieldValue)) {
                          return "Last name must contain at least one alphabet character.";
                        }
                        if (/^\d+$/.test(fieldValue)) {
                          return "Last name should not contain only digits.";
                        }
                        if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                          return "Last name should only contain alphabets, spaces, -,numbers and & symbol.";
                        }
                        return true;
                      },
                    },
                  })}
                  name="lastName"
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName?.message}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Enter your lastName"
                  InputProps={{
                    inputProps: { maxLength: 30 },
                    startAdornment: (
                      <>
                        <Person
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </Mui.Grid> */}
            </Mui.Grid>
            {/* BirthDate,Gender */}
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Birth Date
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  id="from-date"
                  type="date"
                  sx={{ background: themes.whiteColor }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <CalendarMonthSharp
                          style={{ margin: "0 8px", color: "#a6a6a6" }}
                        />
                      </>
                    ),
                  }}
                  {...register("birthDate", { required: false })}
                  onChange={(event) => handleDateChange(event.target.value)}
                  fullWidth
                  disableOpenPicker
                />
                {dateValidation !== "" ? (
                  <Mui.Typography
                    sx={{
                      backgroundcolor: themes.whiteColor,
                      color: themes.redColor,
                      fontSize: 15,
                    }}
                  >
                    {dateValidation}
                  </Mui.Typography>
                ) : null}
                {errors.birthDate ? (
                  <Mui.Typography
                    sx={{
                      backgroundColor: themes.formBackground,
                      color: themes.redColor,
                      fontSize: 15,
                    }}
                  >
                    Date of birthday is required
                  </Mui.Typography>
                ) : (
                  <Mui.Typography
                    sx={{
                      marginBottom: 2,
                    }}
                  ></Mui.Typography>
                )}
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Gender
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={currentUserGender}
                  options={gender}
                  sx={{ width: "100%" }}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.gender
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.gender}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setCurrentUserGender(value.gender);
                    }
                  }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={currentUserGender}
                      {...register("gender", {
                        required: false,
                      })}
                      sx={{ background: themes.whiteColor }}
                      placeholder="Select the gender"
                      size="medium"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <ManIcon
                              style={{ margin: "0 8px", color: "#a6a6a6" }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Mui.Grid>
            </Mui.Grid>
            {/* Rate per hour and ProvitedLeave */}
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Hourly rate
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  {...register("rate", {
                    required: false,
                  })}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                  }}
                  error={errors.rate ? true : false}
                  helperText={errors.rate?.message}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Annual Leaves
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  {...register("annual_leaves", {
                    required: "Atleast one digits required",
                  })}
                  InputProps={{
                    inputProps: { maxLength: 2 },
                  }}
                  error={errors.annual_leaves ? true : false}
                  helperText={errors.annual_leaves?.message}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            {/* Address */}
            <Mui.Grid container>
              <Mui.Grid xs={12} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Address
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("address", {
                    required: false,
                  })}
                  name="address"
                  error={errors.address ? true : false}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Enter your address"
                  InputProps={{
                    inputProps: { maxLength: 1024 },
                    startAdornment: (
                      <>
                        <AddLocationAlt
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            {/* Country */}
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Country
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={currentUserCountry}
                  options={allCountries}
                  sx={{ width: "100%" }}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.label}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setCurrentUserCountry(value.label);
                      getCountryStates(value.id);
                    } else {
                      setCurrentUserCountry("");
                      setCurrentUserState("");
                      setValue("state", "");
                      setValue("country", "");
                    }
                  }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={currentUserCountry}
                      {...register("country", {
                        required: false,
                      })}
                      sx={{ background: themes.whiteColor }}
                      placeholder="Select Coundry Name"
                      size="medium"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <FlagCircle
                              style={{ margin: "0 8px", color: "#a6a6a6" }}
                            />{" "}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    State
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  value={currentUserState}
                  options={countryStatesList}
                  sx={{ width: "100%" }}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.label}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setCurrentUserState(value.label);
                    }
                  }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={currentUserState}
                      {...register("state", {
                        required: false,
                      })}
                      sx={{ background: themes.whiteColor }}
                      placeholder="Select State Name"
                      size="medium"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <AddRoad
                              style={{ margin: "0 8px", color: "#a6a6a6" }}
                            />{" "}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Mui.Grid>
            </Mui.Grid>
            {/* Pin Code and  Phone Number */}
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Pin Code
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("pinCode", {
                    required: false,
                    minLength: {
                      value: 6,
                      message: "Minimum length is 6 digits",
                    },
                    maxLength: {
                      value: 7,
                      message: "Maximum length is 7 digits",
                    },
                  })}
                  name="pinCode"
                  error={errors.pinCode ? true : false}
                  helperText={errors.pinCode?.message}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Enter your pinCode"
                  InputProps={{
                    inputProps: { maxLength: 7 },
                    startAdornment: (
                      <>
                        <PushPin
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                  onKeyPress={handleKeyPress}
                  onBlur={() => {
                    setValue(
                      "pinCode",
                      getValues("pinCode").replace(/[^\d-]/g, "")
                    );
                  }}
                />
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Phone Number
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("phoneNumber", {
                    required: false,
                    minLength: {
                      value: 10,
                      message: "Minimum length is 10 digits",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum length is 15 digits",
                    },
                  })}
                  name="phoneNumber"
                  error={errors.phoneNumber ? true : false}
                  sx={{ background: themes.whiteColor }}
                  placeholder="Enter your phone number"
                  InputProps={{
                    inputProps: { maxLength: 15 },
                    startAdornment: (
                      <>
                        <LocalPhone
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                  onKeyPress={handleKeyPress}
                  onBlur={() => {
                    setValue(
                      "phoneNumber",
                      getValues("phoneNumber").replace(/[^\d-]/g, "")
                    );
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Aadhar Number
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      marginTop: -1,

                      fontSize: 18,
                    }}
                  >
                    *
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("aadharNumber", {
                    required: "Aadhar Number is required",
                    maxLength: {
                      value: 12,
                      message: "Invalid aadhar number",
                    },
                    minLength: {
                      value: 12,
                      message: "Invalid aadhar number",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        return true;
                      },
                    },
                  })}
                  name="aadharNumber"
                  error={errors.aadharNumber ? true : false}
                  sx={{ background: themes.whiteColor }}
                  helperText={errors.aadharNumber?.message}
                  placeholder="Enter your aadhar number"
                  InputProps={{
                    inputProps: { maxLength: 12 },
                    startAdornment: (
                      <>
                        <ContactEmergency
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                  onKeyPress={handleKeyPress}
                />
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Pan Number
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      marginTop: -1,
                      fontSize: 18,
                    }}
                  >
                    *
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.TextField
                  fullWidth
                  {...register("panNumber", {
                    required: "panNumber is required",
                    maxLength: {
                      value: 10,
                      message: "Invalid pan number",
                    },
                    minLength: {
                      value: 10,
                      message: "Invalid pan number",
                    },
                    validate: {
                      noDigitsOrSpecial: (fieldValue) => {
                        return true;
                      },
                    },
                  })}
                  name="panNumber"
                  value={panNumber}
                  onChange={handlePanChange}
                  error={errors.panNumber ? true : false}
                  sx={{ background: themes.whiteColor }}
                  helperText={errors.panNumber?.message}
                  placeholder="Enter your pan number"
                  InputProps={{
                    inputProps: { maxLength: 10 },
                    startAdornment: (
                      <>
                        <CreditCard
                          style={{
                            margin: "0 8px",
                            color: "#a6a6a6",
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Department
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      marginTop: -1,
                      fontSize: 18,
                    }}
                  >
                    *
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth
                  value={departmentName}
                  options={departmentList}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.department
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.department}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setDepartmentName(value.department);
                    } else {
                      setDepartmentName(null);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={departmentName}
                      {...register("department", {
                        required: "Please select deparment",
                      })}
                      error={errors.department ? true : false}
                      helperText={errors.department?.message}
                      sx={{ background: themes.whiteColor }}
                      name="department"
                      placeholder="Select a Department"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Lan
                              style={{
                                margin: "0 8px",
                                color: "#a6a6a6",
                              }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid xs={6} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Department Lead
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.redColor,
                      marginTop: -1,

                      fontSize: 18,
                    }}
                  >
                    *
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth
                  value={managerName}
                  options={managerList}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.user.auth_user.username
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.user.auth_user.username}</li>;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setManagerName(value.user.auth_user.username);
                      setManagerId(value.id);
                    } else {
                      setManagerName(null);
                      setManagerId(null);
                    }
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      value={managerName}
                      {...register("teamLead", {
                        required: "Please select team lead",
                      })}
                      error={errors.teamLead ? true : false}
                      sx={{ background: themes.whiteColor }}
                      name="teamLead"
                      placeholder="Select team lead name"
                      helperText={errors.teamLead?.message}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Groups
                              style={{
                                margin: "0 8px",
                                color: "#a6a6a6",
                              }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ padding: 2 }}
            >
              <Mui.Grid xs={12}>
                <Mui.Stack sx={{ justifyContent: "center" }}>
                  <CustomButton
                    actionName="Submit"
                    typeName="submit"
                    disableAction={open && buttonLoader ? buttonLoader : false}
                  />
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
          </form>
        </Mui.DialogContent>
      </BootstrapDialog>
    </div>
  );
};

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "3px",
    minWidth: 1200,
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && title !== "Logout" ? (
        <Mui.IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
