import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BackArrow } from "components/BackArrow";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { summaryDetails } from "pages/PayRoll/PayRollHeaders";
import { getStatusColor } from "components/Status";
import { ConstStructure } from "../CostStructure";
import { OverTime } from "../OverTime";
import { SalaryRevision } from "../SalaryRevision";

export const ViewPayRun = ({
  setViewPayRunScreen,
  companyId,
  companyName,
  viewPayrunDetails,
  setViewPayRunDetails,
}) => {
  const [summaryName, setSummaryName] = useState("Cost Structure");
  const [payrollCost, setPayrollCost] = useState(0);
  const [employeeNetPay, setEmployeeNetPay] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const handleSummaryDetails = (event, data) => {
    setSummaryName(data?.label);
  };

  const renderComponent = () => {
    switch (summaryName) {
      case "Cost Structure":
        return (
          <ConstStructure
            viewPayrunDetails={viewPayrunDetails}
            setPayrollCost={setPayrollCost}
            setEmployeeNetPay={setEmployeeNetPay}
            setTotalDeduction={setTotalDeduction}
            setTotalWorkingHours={setTotalWorkingHours}
            companyId={companyId}
          />
        );
      case "Over Time":
        return <OverTime viewPayrunDetails={viewPayrunDetails} />;
      case "Salary Revision":
        return <SalaryRevision viewPayrunDetails={viewPayrunDetails} />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={12}>
          <Mui.Stack direction="Row">
            <BackArrow actionFunction={() => setViewPayRunScreen(false)} />
            <Mui.Typography
              sx={{ paddingTop: 1, fontSize: 25, fontWeight: "bold" }}
            >
              Pay runs - {companyName} -{summaryName}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={5}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Grid xs={8}>
              Period :{" "}
              {viewPayrunDetails.start_date +
                " to " +
                viewPayrunDetails.end_date}
            </Mui.Grid>
            <Mui.Grid xs={4}>{totalWorkingHours} Working days</Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container component={Mui.Paper} elevation={10}>
            <Mui.Grid
              xs={4}
              sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Typography sx={{ paddingLeft: 2 }}>
                {payrollCost}
              </Mui.Typography>
              <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                Payroll cost
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={4}
              sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Typography sx={{ paddingLeft: 2 }}>
                {employeeNetPay}
              </Mui.Typography>
              <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                Employee's net pay
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={4}
              sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
            >
              <Mui.Typography sx={{ paddingLeft: 2 }}>
                {totalDeduction}
              </Mui.Typography>
              <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                Total Deductions
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid xs={7}>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Mui.Box
              sx={{
                width: 120,
                height: 15,
                backgroundColor: getStatusColor(viewPayrunDetails.status),
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themes.backgroundWhite,
                p: 2,
                fontSize: 13,
              }}
            >
              {viewPayrunDetails.status}
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid xs={9}></Mui.Grid>
        <Mui.Grid xs={3}>
          <CustomAutoComplete
            dataList={summaryDetails}
            selectedValue={summaryName}
            updateDataFunction={handleSummaryDetails}
            placeHolder="Please select the summary"
            fullWith
          />
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container>{renderComponent()}</Mui.Grid>
    </React.Fragment>
  );
};
