import React, { useEffect, useState, useRef } from "react";
import * as Mui from "@mui/material";
import { showToast } from "components/Status";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MoreVert } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomButton } from "components/CustomButton";
import { Loader } from "components/Loader";
import { EditShiftCategory } from "./EditShiftCategory";
import { setGlobalSearchValue } from "services/Redux/userToken";
import dayjs from "dayjs";
import sessionHandling from "services/utils/notificationUtils";
import { useDispatch } from "react-redux";
import { shiftCatagoryHeader, shiftHeader } from "../AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";
export const ShiftCategory = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [shiftCategoryList, setShitCategoryList] = useState([]);
  const [masterShiftCategoryList, setMasterShiftCategoryList] = useState([]);
  const [showShiftCategaryDetails, setShowShiftCategaryDetails] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, isLoading] = useState(false);
  const [editShiftCategoryData, setEditShiftCategoryData] = useState([]);
  const [showEditShiftCategory, setShowEditShiftCategory] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [getShiftCategoryApi, setGetShiftCategoryApi] = useState(true);
  const [editShiftCategory, setEditShitCategory] = useState([]);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    scrollTop(tableContainerRef);
    const getShiftCategory = async () => {
      isLoading(true);
      try {
        const response = await fetch(`${domain}list_shift_categories/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setMasterShiftCategoryList(res);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (getShiftCategoryApi) {
      getShiftCategory();
      setGetShiftCategoryApi(false);
    }
  }, [domain, getShiftCategoryApi, token, page]);

  const handleChangePage = (event, newPage) => {
    setShowShiftCategaryDetails(0);
    setAnchorEl(null);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setShowShiftCategaryDetails(0);
    setAnchorEl(null);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setShowShiftCategaryDetails(0);
    setAnchorEl(null);
  };

  useEffect(() => {
    const searchFields = ["name", "number_of_shifts"];
    const filteredResults = masterShiftCategoryList?.filter((item) =>
      searchFields?.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setShitCategoryList(filteredResults);
    setPage(0);
    setShowShiftCategaryDetails(0);
    setAnchorEl(null);
  }, [globalSearchValue, masterShiftCategoryList]);

  const convertShiftTimeISOFormat = (item) => {
    let editableData = item;
    let updateData = editableData?.shifts?.map((data) => {
      return {
        ...data,
        break_time: dayjs()
          .set("hour", data?.break_time?.split(":")[0])
          .set("minute", data?.break_time?.split(":")[1])
          .set("second", data?.break_time?.split(":")[2]),
        start_time: dayjs()
          .set("hour", data?.start_time?.split(":")[0])
          .set("minute", data?.start_time?.split(":")[1])
          .set("second", data?.start_time?.split(":")[2]),
        min_start_time: dayjs()
          .set("hour", data?.min_start_time?.split(":")[0])
          .set("minute", data?.min_start_time?.split(":")[1])
          .set("second", data?.min_start_time?.split(":")[2]),
        max_start_time: dayjs()
          .set("hour", data?.max_start_time.split(":")[0])
          .set("minute", data?.max_start_time.split(":")[1])
          .set("second", data?.max_start_time.split(":")[2]),
        end_time: dayjs()
          .set("hour", data?.end_time.split(":")[0])
          .set("minute", data?.end_time.split(":")[1])
          .set("second", data?.end_time.split(":")[2]),
        min_end_time: dayjs()
          .set("hour", data?.min_end_time.split(":")[0])
          .set("minute", data?.min_end_time.split(":")[1])
          .set("second", data?.min_end_time.split(":")[2]),
        max_end_time: dayjs()
          .set("hour", data?.max_end_time.split(":")[0])
          .set("minute", data?.max_end_time.split(":")[1])
          .set("second", data?.max_end_time.split(":")[2]),
      };
    });
    editableData.shifts = updateData;
    setEditShiftCategoryData(editableData);
  };

  const navigateToAddShiftCategory = () => {
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/add/shift/category");
    setShowShiftCategaryDetails(0);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : showEditShiftCategory ? (
        <React.Fragment>
          <EditShiftCategory
            editShiftCategoryData={editShiftCategoryData}
            setShowEditShiftCategory={setShowEditShiftCategory}
            setGetShiftCategoryApi={setGetShiftCategoryApi}
          />
        </React.Fragment>
      ) : (
        <>
          <Mui.Grid
            container
            sx={{
              paddingTop: 2,
              paddingRight: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignSelf: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={navigateToAddShiftCategory}
              actionName="Add Shift Category"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.TableContainer sx={tableContainerSx} ref={tableContainerRef}>
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {shiftCatagoryHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {shiftCategoryList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <>
                      <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.id === showShiftCategaryDetails ? (
                            <Mui.IconButton
                              onClick={() => setShowShiftCategaryDetails(0)}
                            >
                              <KeyboardArrowDownIcon />
                            </Mui.IconButton>
                          ) : (
                            <Mui.IconButton
                              onClick={() => {
                                setShowShiftCategaryDetails(item?.id);
                                setAnchorEl(null);
                              }}
                              style={{
                                padding: "0px",
                              }}
                            >
                              <ChevronRightIcon />
                            </Mui.IconButton>
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.name ? item?.name : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell
                          align="left"
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {item?.number_of_shifts
                            ? item?.number_of_shifts
                            : "N/A"}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(event) => {
                              setEditShitCategory(item);
                              setAnchorEl(event.currentTarget);
                              setShowShiftCategaryDetails(0);
                            }}
                          >
                            <MoreVert />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => {
                              handleMenuClose();
                            }}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={(e) => {
                                setShowEditShiftCategory(true);
                                handleMenuClose();
                                setAnchorEl(null);
                                convertShiftTimeISOFormat(editShiftCategory);
                              }}
                            >
                              Edit
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      </Mui.TableRow>
                      {showShiftCategaryDetails === item?.id && (
                        <Mui.TableRow>
                          <Mui.TableCell colSpan={7}>
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%",
                              }}
                              className="w-96 border rounded"
                            >
                              {item?.shifts?.length !== 0 ? (
                                <Mui.Table className="border">
                                  <Mui.TableHead sx={tableHeaderSx}>
                                    <Mui.TableRow
                                      maxWidth="xl"
                                      align="left"
                                      sx={tableRowSx}
                                    >
                                      {shiftHeader?.map?.((shift) => (
                                        <Mui.TableCell
                                          sx={{
                                            color: themes.blackColor,
                                            fontWeight: "bold",
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.name}
                                        </Mui.TableCell>
                                      ))}
                                    </Mui.TableRow>
                                  </Mui.TableHead>
                                  <Mui.TableBody>
                                    {item?.shifts?.map((shift, index) => (
                                      <Mui.TableRow
                                        key={index}
                                        maxWidth="xl"
                                        align="left"
                                        sx={tableRowSx}
                                      >
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.name ? shift?.name : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.start_time
                                            ? shift?.start_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.min_start_time
                                            ? shift?.min_start_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.max_start_time
                                            ? shift?.max_start_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.end_time
                                            ? shift?.end_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.min_end_time
                                            ? shift?.min_end_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                        <Mui.TableCell
                                          align="left"
                                          sx={{
                                            fontSize: tableCellFontSize,
                                          }}
                                        >
                                          {shift?.max_end_time
                                            ? shift?.max_end_time
                                            : "N/A"}
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    ))}
                                  </Mui.TableBody>
                                </Mui.Table>
                              ) : (
                                <Mui.Stack
                                  sx={{ padding: 0.5 }}
                                  spacing={2}
                                  fullWidth
                                >
                                  <Mui.Alert severity="info">
                                    There is a no available shifts
                                  </Mui.Alert>
                                </Mui.Stack>
                              )}
                            </Mui.TableContainer>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      )}
                    </>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {shiftCategoryList?.length === 0 ? (
              <Mui.Alert sx={{ marginTop: 3 }} severity="info">
                No data available
              </Mui.Alert>
            ) : null}
          </Mui.TableContainer>
        </>
      )}
      {showEditShiftCategory
        ? null
        : shiftCategoryList?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={shiftCategoryList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
    </React.Fragment>
  );
};
