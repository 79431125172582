import React, { useState, useEffect, useContext } from "react";
import * as Mui from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import moment from "moment";
import { TaskContext } from "../TaskContext";
import { showToast } from "components/Status";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

export const History = ({ selectedTask }) => {
  const [taskHistory, setTaskHistory] = useState([]);
  const [lastHistoryName, setLastHistoryName] = useState(null);
  const { domain, token } = useContext(TaskContext);

  useEffect(() => {
    const fetchTaskHistory = async () => {
      try {
        const response = await fetch(
          `${domain}tasks/${selectedTask.id}/history/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setTaskHistory(res);
          setLastHistoryName(res[res?.length - 1]?.event_type);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchTaskHistory();
  }, [domain, selectedTask.id, token]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingLeft: 2 }}>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {taskHistory.map((data) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  color="info"
                  style={{ width: "20px", height: "18px" }}
                />
                {lastHistoryName === data.event_type ? null : (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Mui.Card
                  sx={{ display: "flex", borderRadius: 3 }}
                  elevation={5}
                >
                  <Mui.CardMedia
                    sx={{
                      width: 200,
                      backgroundColor: themes.primaryIndicator,
                      borderRadius: 3,
                      flexShrink: 0,
                    }}
                  >
                    <Mui.Stack
                      direction="row"
                      spacing={2}
                      sx={{ overflow: "hidden", m: 2, mb: 0, mt: 1 }}
                    >
                      <Mui.Avatar
                        alt={data.user_name.charAt(0)}
                        src={data.image_url}
                      />
                      <Mui.Tooltip title={data.user_name}>
                        <Mui.Typography
                          sx={{
                            pt: "8px",
                            color: themes.headLine,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {data.user_name}
                        </Mui.Typography>
                      </Mui.Tooltip>
                    </Mui.Stack>
                    <Mui.Typography
                      sx={{ m: 2, mt: 1, mb: 1, color: themes.headLine }}
                    >
                      {moment(data.timestamp).format("MMM DD, YY hh:mm a")}
                    </Mui.Typography>
                  </Mui.CardMedia>
                  <Mui.Box
                    sx={{
                      display: "flex",
                      borderRadius: 25,
                      flex: 1,
                    }}
                  >
                    <Mui.CardContent
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Mui.Typography
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 18,
                        }}
                      >
                        {data?.event_type}
                        <br />
                        {data?.details}
                      </Mui.Typography>
                    </Mui.CardContent>
                  </Mui.Box>
                </Mui.Card>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Mui.Grid>
    </React.Fragment>
  );
};
