import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { administrationList } from "services/constants";
import { administrationCard } from "services/constants";
import { themes,   } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import ListIcon from "@mui/icons-material/List";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { EditDepartment } from "./EditDepartment";
import { ListView } from "./ListView";
import { DialogBox } from "./DialogBox";
import { CardView } from "./CardView";
import { AddDepartment } from "./AddDepartment";
import { useDispatch } from "react-redux";
import { setDepartmentViewMethod } from "services/Redux/userToken";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const Departments = () => {
  const [open, setOpen] = useState(false);
  const { token, domain, departmentViewMethod, globalSearchValue } =
    useSelector((state) => state.tokenReducer);
  const dispatch = useDispatch();
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentApi, setDepartmentApi] = useState(true);
  const [userDataViewMethod, setUserDataViewMethod] =
    useState(departmentViewMethod);
  const [suspandPopup, setSuspandPopup] = useState(false);
  const [callSuspandApi, setCallSuspandApi] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEditDepartmentDetails, setCurrentEditDepartmentDetails] =
    useState({});
  const [openEditScreen, setOpenEditScreen] = useState(false);
  const [editDepartmentDetails, setEditDepartmentDetails] = useState([]);
  const [page, setPage] = useState(0);
  const handleOpenDepartment = () => {
    setOpen(true);
  };
  useEffect(() => {
    setCurrentEditDepartmentDetails(""); //remove this line later
    const fetchTeam = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}get_leads_based_on_departments/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentList(
            res.filter((data) => {
              return data.department_name !== "All";
            })
          );
          setMasterDataForSearch(
            res.filter((data) => {
              return data.department_name !== "All";
            })
          );
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentApi) {
      fetchTeam();
      setDepartmentApi(false);
    }
  }, [departmentApi, domain, token]);

  const handleClose = () => {
    setSuspandPopup(false);
  };

  useEffect(() => {
    const updateTeamSuspend = async () => {
      try {
        const response = await fetch(`${domain}suspend_department/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            department_id: currentEditDepartmentDetails.department_id,
            department_lead_id: currentEditDepartmentDetails.department_lead_id,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("error", "The Department has been successfully suspended");
          setDepartmentApi(false);
          setSuspandPopup(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (callSuspandApi) {
      updateTeamSuspend();
      setCallSuspandApi(false);
    }
  }, [
    callSuspandApi,
    currentEditDepartmentDetails.department_id,
    currentEditDepartmentDetails.department_lead_id,
    domain,
    setCallSuspandApi,
    setSuspandPopup,
    token,
  ]);

  useEffect(() => {
    let filterKeys =
      userDataViewMethod === "CardView"
        ? administrationCard
        : administrationList;
    const filteredResults = masterDataForSearch.filter((item) =>
      filterKeys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setDepartmentList(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch, userDataViewMethod]);

  return (
    <React.Fragment 
    >
      {open ? (
        openEditScreen ? null : (
          <AddDepartment
            setOpen={setOpen}
            setDepartmentApi={setDepartmentApi}
          />
        )
      ) : null}
      {openEditScreen ? (
        <EditDepartment
          setOpenEditScreen={setOpenEditScreen}
          editDepartmentDetails={editDepartmentDetails}
          setDepartmentApi={setDepartmentApi}
        />
      ) : open ? null : (
        <Mui.Grid
          container
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Mui.Grid
            sx={{
              paddingRight: 1,
            }}
          >
            <Mui.Stack direction="row">
              <Mui.IconButton
                onClick={() => {
                  dispatch(
                    setDepartmentViewMethod(
                      userDataViewMethod === "ListView"
                        ? "CardView"
                        : "ListView"
                    )
                  );
                  setUserDataViewMethod(
                    userDataViewMethod === "ListView" ? "CardView" : "ListView"
                  );
                }}
              >
                <Mui.Tooltip
                  key={userDataViewMethod}
                  title={
                    userDataViewMethod === "CardView" ? "ListView" : "CardView"
                  }
                  placement="bottom"
                >
                  {userDataViewMethod === "CardView" ? (
                    <ListIcon
                      style={{
                        fontSize: 35,
                        color: "#7D7D7D",
                        fontWeight: "bold",
                      }}
                    />
                  ) : (
                    <CreditCardIcon
                      style={{
                        fontSize: 35,
                        color: "#7D7D7D",
                        fontWeight: "bold",
                      }}
                    />
                  )}
                </Mui.Tooltip>
              </Mui.IconButton>
              <CustomButton
                actionFuntion={handleOpenDepartment}
                actionName="Add Department"
                typeName="button"
              />
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      )}
      {userDataViewMethod === "ListView" ? (
        openEditScreen || open ? null : (
          <ListView
            departmentList={departmentList}
            setCurrentEditDepartmentDetails={setCurrentEditDepartmentDetails}
            setSuspandPopup={setSuspandPopup}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            setOpenEditScreen={setOpenEditScreen}
            setEditDepartmentDetails={setEditDepartmentDetails}
            setDepartmentApi={setDepartmentApi}
          />
        )
      ) : openEditScreen || open ? null : (
        <CardView
          departmentList={departmentList}
          setCurrentEditDepartmentDetails={setCurrentEditDepartmentDetails}
          setSuspandPopup={setSuspandPopup}
          isLoading={isLoading}
          userDataViewMethod={userDataViewMethod}
          setOpenEditScreen={setOpenEditScreen}
          setEditDepartmentDetails={setEditDepartmentDetails}
          setDepartmentApi={setDepartmentApi}
        />
      )}
      <DialogBox
        open={suspandPopup}
        handleClose={handleClose}
        title="Team suspend"
      >
        <Mui.Typography style={{ fontSize: 20, padding: 5 }}>
          Do You want to Suspend this Department?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid item xs={5}></Mui.Grid>
          <Mui.Grid item xs={3.5} sx={{ padding: 1 }}>
            <Mui.Button
              sx={{
                background: themes.btnBackgroundColor,
                width: "100%",
                tableLayout: "fixed",
                borderCollapse: "collapse",
              }}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Mui.Button>
          </Mui.Grid>
          <Mui.Grid item xs={3.5} sx={{ padding: 1 }}>
            <Mui.Button
              sx={{
                background: themes.btnBackgroundColor,
                width: "100%",
                tableLayout: "fixed",
                borderCollapse: "collapse",
              }}
              variant="contained"
              type="submit"
              onClick={() => setCallSuspandApi(true)}
            >
              Suspend
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </DialogBox>
    </React.Fragment>
  );
};
