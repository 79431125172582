export const themes = {
  // primary: "#4FD1C5",
  primary: "#F2F2F7",
  primaryButton: "#84DEB8",
  primaryIndicator: "#84DEB8",
  primaryHeader: "#607eeb",
  headLine: "#000000",
  whiteColor: "white",
  tableHeader: "#4FD1C5",
  taskBorder: "#E9ECEF",
  iconColor: "white",
  taskHeader: "#B6B6B6",
  button: "#00cca3",
  tabIndicator: 1,
  allTap: "#065BAA",
  activeTap: "#149611",
  absentTap: "#FF0000",
  outTap: "#000000",
  permissionTap: "#8B198D",
  breakTap: "#EC00F1",
  onleaveTap: "#CB680D",
  lateTap: "#09AEB9",
  backgroundWhite: "white",
  backgroundShadow: "#D9D9D9",
  cardProjectName: "#3C557A",
  secondryButtonColor: "#9C27B0",
  primaryButtonColor: "#1976D2",
  projectActiveTapColor: "#231F1F",
  activeProject: "#21AB79",
  inactiveProject: "#C35353",
  completedProject: "#F41062",
  holdProject: "#F4CC40",
  formBackground: "#f0f3f5",
  sprintBackground: "#898989",
  workingHours: "#081A51",
  dividerBorder: "#B2B2B5",
  tagBlurColor: "#17285C",
  helperTextColor: "#D32F2F",
  newUser: "#7BDDD3",
  allIconColor: "#a6a6a6",
  shadowColor: "#bcb9bd",
  redColor: "red",
  blueColor: "#34c6eb",
  lightGray: "#a1a9b2",
  blackColor: "#000000",
  buttonBorder: "#D0D5DD",
  colorShadow: "#7D8488",
  showSprintColor: "#715E5E",
  taskBtnBck: "#036EFF",
  proWrkFlwClr: "#351EC3",
  sprintDialogColor: "#3FCEC6",
  greenColor: "#008000",
  backgroundColor: "#3FCEC6",
  bgIconColor: "#7D7D7D",
  txtErrorColor: "#DB2F2F",
  btnBackgroundColor: "#8BE1D8",
  lightGreen: "#34eb8c",
  buttonBackground: "#cccccc",
  sidebarDivider: "#E0E0E0",
  generalSubHeader: "#A2A3A7",
  yellowColor: "#ffcc00",
  lightRedColor: "#ff5d5d",
  lightYellowColor: "#fffd8d",
  balanceColor: "#B79B08",
  lightOrange: "#FF9169",
  shadowText: "#8C94A3",
  lightBrown: "#d9d9d9",
  leaveGreen: "#5aaa63",
  leaveDarkBlue: "#3859aa",
  leaveLightBlue: "#3490a6",
  leaveDarkPink: "#ab3662",
  leaveBrown: "#9b4c2f",
};

export const tableContainerSx = {
  mt: 1,
  width: "100%",
  maxHeight: { md: 650, lg: 400, xl: 700 },
};

export const tableHeaderSx = {
  backgroundColor: themes.primary,
  position: "sticky",
  top: -1,
  zIndex: 10,
};

export const tableRowSx = {
  "& .MuiTableCell-root": {
    height: "15px",
    padding: "6px 6px",
  },
};

export const tableCellFontSize = { md: 8, lg: 12, xl: 15 };

export const scrollTop = (tableContainerRef) => {
  if (tableContainerRef.current) {
    tableContainerRef.current.scrollTop = 0;
  }
};

export const weeks = [
  {
    id: 1,
    weeks: "One Week",
    label: "One Week",
  },
  {
    id: 2,
    weeks: "Two Weeks",
    label: "Two Weeks",
  },
  {
    id: 3,
    weeks: "Three Weeks",
    label: "Three Weeks",
  },
  {
    id: 4,
    weeks: "Four Weeks",
    label: "Four Weeks",
  },
  {
    id: 5,
    weeks: "Five Weeks",
    label: "Five Weeks",
  },
  {
    id: 6,
    weeks: "Six Weeks",
    label: "Six Weeks",
  },
  {
    id: 7,
    weeks: "Custom",
    label: "Custom",
  },
];

export const taskPriority = [
  {
    id: 1,
    priority: "Low",
    label: "Low",
  },
  {
    id: 2,
    priority: "Medium",
    label: "Medium",
  },
  {
    id: 3,
    priority: "High",
    label: "High",
  },
];

export const taskTypeArr = [
  {
    id: 1,
    label: "All",
  },
  {
    id: 2,
    label: "Parent Task",
  },
  {
    id: 3,
    label: "Child Task",
  },
];

export const toastContainerStyle = {
  width: "500px",
  margin: "0 auto",
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
};

export const changePassword = {
  successType: "success",
  successAlert:
    "You have successfully changed your password.Please logout and login with new password ",
  failureType: "danger",
  failureAlert: "Old Password incorrect",
};
export const defaultLead = null;
export const selectedField = null;
export const buttonAndTableHeaderColor = {
  button: "#8BE1D8",
  TableHeader: "#4FD1C5",
  formHeader: "#4FD1C5",
};

export const subMenuIndicator = {
  indicator: "#C1EEEA",
};

export const gender = [
  { id: 1, gender: "Male", label: "Male" },
  { id: 2, gender: "Female", label: "Female" },
];

export const country = [
  { id: 1, country: "Oman", label: "Oman" },
  {
    id: 2,
    country: "India",
    label: "India",
  },
];

export const countryState = [
  { id: 1, stateName: "Tamil Nadu", countryId: 2 },
  {
    id: 2,
    stateName: "Punjab",
    label: "Punjab",
    countryId: 2,
  },
  {
    id: 3,
    stateName: "Kerala",
    label: "Kerala",
    countryId: 2,
  },
  {
    id: 4,
    stateName: "Al Buraimi",
    label: "Al Buraimi",
    countryId: 1,
  },
  {
    id: 5,
    stateName: "Muscat",
    label: "Muscat",
    countryId: 1,
  },
  {
    id: 6,
    stateName: "Muscat",
    label: "Muscat",
    countryId: 1,
  },
];

//User management  search keys

export const employeeCardKeys = ["user_name", "designation"];
export const employeeListKeys = [
  "user_name",
  "user_code",
  "department",
  "employment_type",
  "company_name",
  "designation",
  "department_lead_name",
  "system_access",
  "status",
];
export const teamCardKeys = ["team_name", "team_lead_name", "proxy_name"];
export const teamListKeys = [
  "team_name",
  "team_lead_name",
  "email",
  "proxy_name",
  "created",
];

//Permission Type on Leave apply screen

export const PermissionType = [
  {
    id: 1,
    permissionType: "Bus Late",
  },
  {
    id: 2,
    permissionType: "Medical Emergency ",
  },
  {
    id: 3,
    permissionType: "Others",
  },
];

export const siteSettingsEditToastMessage = {
  editMessage: "Site status updated successfully",
};

export const leaveTaxYear = [
  {
    year: "2023-2024",
    label: "2023-2024",
    yearDate: "2023-2024 (01/04/2023 - 31/03/2024)",
  },
  {
    year: "2024-2025",
    label: "2024-2025",
    yearDate: "2024-2025 (01/04/2024 - 31/03/2025)",
  },
  {
    year: "2025-2026",
    label: "2025-2026",
    yearDate: "2025-2026 (01/04/2025 - 31/03/2026)",
  },
  {
    year: "2026-2027",
    label: "2026-2027",
    yearDate: "2026-2027 (01/04/2026 - 31/03/2027)",
  },
];

export const dateLabels = [
  {
    dateLabel: "This week",
    label: "This week",
  },
  {
    dateLabel: "Last week",
    label: "Last week",
  },
  {
    dateLabel: "This month",
    label: "This month",
  },
  {
    dateLabel: "Last month",
    label: "Last month",
  },
  {
    dateLabel: "This year",
    label: "This year",
  },
  {
    dateLabel: "Last year",
    label: "Last year",
  },
  {
    dateLabel: "Custom",
    label: "Custom",
  },
];

export const dateFormat = "DD-MM-YYYY";
export const yearMonth = "YYYY-MM-DD";
//Project screen filter

export const filterProject = [
  "projectname",
  "clientName",
  "project_lead_name",
  "projectType",
  "status",
  "completed_tasks",
  "total_tasks",
  "date",
];

export const essPermissions = [
  "status",
  "date",
  "start_time",
  "end_time",
  "total_time",
];

export const essApplyLeave = [
  "leave_type",
  "from_date",
  "to_date",
  "business_days",
  "status",
  "description",
];

export const clientFilterKey = [
  "clientname",
  "clientid",
  "address",
  "gstnumber",
  "email",
  "phonenumber",
];

export const defaultAllSelection = 99999;

export const leaveTypeColors = {
  Vacation: "#8272C0",
  Sick: "#F46070",
  Casual: "#EA6EE4",
  Bereavement: "#348A9C",
};

export const leaveFilterKey = [
  "user_name",
  "role",
  "total_leave",
  "planned_leave",
  "taken_leave",
  "available_leave",
];

export const attendanceFilterList = [
  "department_name",
  "department_lead_name",
  "email",
  "proxy_name",
  "created",
];

export const attendanceFilterCard = [
  "department_name",
  "members_count",
  "check_in_count",
];

export const rejectedFilter = [
  "username",
  "department_name",
  "title",
  "option",
  "from_date",
  "to_date",
  "business_days",
  "approver_name",
  "description",
];

export const administrationList = [
  "department_name",
  "department_lead_name",
  "email",
  "proxy_name",
  "username",
  "department_member_name",
  "date_of_join",
  "phonenumber",
  "id",
  "created",
];

export const administrationCard = [
  "department_name",
  "members_count",
  "check_in_count",
];

export const administrationCompanies = [
  "companyname",
  "company_code",
  "gstnumber",
  "email",
  "phonenumber",
  "address",
  "system_access",
  "isactive",
  "isprimary",
];

export const pendingFilter = [
  "username",
  "department_name",
  "business_days",
  "from_date",
  "to_date",
];

export const essLeaveFilter = [
  "leave_type",
  "from_date",
  "to_date",
  "business_days",
  "status",
];

export const essPermissionFilter = [
  "permission_type",
  "date",
  "start_time",
  "end_time",
  "status",
];

export const hrmsEmployeeFliter = [
  "user_name",
  "employee_id",
  "email",
  "status",
];

export const individualTaskList = [
  "task_code",
  "due_date",
  "taskname",
  "task_owner.username",
  "current_assignee.username",
  "work_flow_status.name",
  "priority.name",
];
export const projectDashboard = [
  "sprints.name",
  "sprints.description",
  "sprints.start_date",
  "sprints.end_date",
  "sprints.status",
  "sprints.total_tasks",
  "sprints.completed_tasks",
];
export const projectMyTaskList = [
  "taskname",
  "task_code",
  "project.projectname",
  "sprint.name",
  "work_flow_status.name",
  "priority.name",
  "due_date",
];

export const sprintTaskDetails = [
  "task_code",
  "taskname",
  "task_owner.username",
  "total_time",
  "due_date",
  "priority.name",
  "work_flow_status.name",
];

export const essMyLeaves = [
  "leave_type",
  "from_date",
  "description",
  "business_days",
];

export const leaveGroupMasterData = [
  "leave_type",
  "leave_per_period",
  "user_name",
  "email",
  "user_code",
];

export const masterUserData = ["user_code", "user_name", "email"];

export const leaveTypeHrms = [
  "leave_type",
  "leave_per_period",
  "can_apply_beyond_balance",
];
export const sitesettingsTeamZone = [
  "location_name",
  "address",
  "radius",
  "tag",
  "username",
  "location_status",
];
export const hrmsEmployee = ["user_name", "employee_id", "email"];
export const userGroupNameFilter = ["name", "users_count"];

export const leavePeriodHrms = ["name", "start_date", "end_date"];

export const punchInDetails = ["username", "punch_in_count"];

export const leaveGroupHrms = [
  "name",
  "leave_type",
  "leave_per_period",
  "user_name",
  "email",
  "user_code",
];

export const paymentBank = [
  "bank_name",
  "nick_name",
  "bank_account_number",
  "bank_branch",
  "bank_ifsc_code",
];

export const leaveCreditingShedule = [
  { label: "Quarterly" },
  { label: "Half Yearly" },
  { label: "Annually" },
];

export const leaveAvailabilityPeriod = [
  { label: "1 Month" },
  { label: "2 Month" },
  { label: "3 Month" },
  { label: "4 Month" },
  { label: "5 Month" },
  { label: "6 Month" },
  { label: "7 Month" },
  { label: "8 Month" },
  { label: "9 Month" },
  { label: "10 Month" },
];

export const checkInPolicyDetails = [
  { id: 1, label: "First In", policy: "first_in" },
  { id: 2, label: "Last In", policy: "last_in" },
];
export const checkOutPolicyDetails = [
  { id: 1, label: "First Out", policy: "first_out" },
  { id: 2, label: "Last Out", policy: "last_out" },
];

//Reports
export const reportNames = [
  {
    label: "Summarized working hours",
  },
  {
    label: "Detailed working hours",
  },
  {
    label: "Punch details",
  },
  {
    label: "Over time details",
  },
  {
    label: "Missing entries",
  },
];

export const reports = [
  {
    id: 1,
    label: "Summarized working hours",
  },
  {
    id: 2,
    label: "Detailed working hours",
  },
  {
    id: 3,
    label: "Punch details",
  },
  {
    id: 4,
    label: "Over time details",
  },
];
export const employee_use = [
  {
    id: 1,
    label: "Company accommodation",
  },
  {
    id: 2,
    label: "Company transport",
  },
  {
    id: 3,
    label: "Own transport",
  },
];

export const salaryType = [
  {
    id: 1,
    label: "Daily",
  },
  {
    id: 2,
    label: "Weekly",
  },
  {
    id: 3,
    label: "Monthly",
  },
];

export const payrollReports = [
  {
    id: 1,
    label: "Salary Statement Report",
  },
  {
    id: 2,
    label: "ESI Report",
  },
  {
    id: 3,
    label: "EPF Report",
  },
];
