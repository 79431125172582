import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TablePagination from "@mui/material/TablePagination";
import { leaveTaxYear } from "services/constants";
import * as Mui from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { Loader } from "components/Loader";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { AddHoliday } from "./AddHoliday";
import { CustomButton } from "components/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { Cancel, Done, Edit, Delete } from "@material-ui/icons";
import { holidayHeader } from "../AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";
const Holidays = () => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dateFormat = "YYYY/MM/DD";
  const nextYear = parseInt(moment(new Date(), dateFormat).format("YYYY")) + 1;
  const previousYear =
    parseInt(moment(new Date(), dateFormat).format("YYYY")) - 1;
  const taxCurrentYear =
    moment(new Date(), dateFormat).format("M") > 3
      ? parseInt(moment(new Date(), dateFormat).format("YYYY"))
      : previousYear;
  const nextTaxYear =
    moment(new Date(), dateFormat).format("M") > 3
      ? nextYear
      : parseInt(moment(new Date(), dateFormat).format("YYYY"));
  const [showForm, setShowForm] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [masterDataLeaves, setMasterDataLeaves] = useState([]);
  const [editLeaveId, setEditLeaveId] = useState(null);
  const [editedFromDate, setEditedFromDate] = useState("");
  const [editedLeaveType, setEditedLeaveType] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [taxYear, setTaxYear] = useState(
    moment(new Date(), dateFormat).format("M") > 3
      ? moment(new Date(), dateFormat).format("YYYY") + "-" + nextYear
      : previousYear +
          "-" +
          parseInt(moment(new Date(), dateFormat).format("YYYY"))
  );

  const [taxYearStartDate, setTaxYearStartDate] = useState(
    taxCurrentYear + "-04-01"
  );
  const [taxYearEndDate, setTaxYearEndDate] = useState(nextTaxYear + "-03-31");
  const [getDataFromApi, setGetDataFromApi] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isEdit, setIsEdit] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const tableContainerRef = useRef(null);
  useEffect(() => {
    scrollTop(tableContainerRef);
  }, [page]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${domain}companyleave/?start_date=${taxYearStartDate}&end_date=${taxYearEndDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setMasterDataLeaves(res);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setLoading(false);
      }
    };
    if (getDataFromApi) {
      fetchData();
      setGetDataFromApi(false);
    }
  }, [domain, getDataFromApi, taxYearEndDate, taxYearStartDate, token]);

  const handleLeaveAdded = (newLeave) => {
    setGetDataFromApi(true);
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleLeaveEdit = (leave) => {
    setEditLeaveId(leave.id);
    const formattedDate = formatDate(leave.date);
    const isoDate = new Date(formattedDate).toISOString().substring(0, 10);
    setEditedFromDate(isoDate);
    setEditedLeaveType(leave.leave_type);
  };

  const handleSaveEdit = async () => {
    setButtonLoader(true);
    const updatedLeaves = leaves.map((leave) => {
      if (leave.id === editLeaveId) {
        return {
          ...leave,
          date: moment(editedFromDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
          leave_type: editedLeaveType,
        };
      }
      return leave;
    });
    try {
      const response = await fetch(`${domain}companyleave/${editLeaveId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          date: editedFromDate,
          leave_type: editedLeaveType,
          createdby: userDetails.firstname,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.firstname,
          modifiedat: new Date().toISOString(),
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Company holiday details updated successfully.");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setGetDataFromApi(true);
      setButtonLoader(false);
      setLeaves(updatedLeaves);
      setEditLeaveId(null);
      setEditedFromDate("");
      setEditedLeaveType("");
      reset();
    }
  };

  const handleCancelEdit = () => {
    setEditLeaveId(null);
    setEditedFromDate("");
    setEditedLeaveType("");
    setIsEdit(false);
    reset();
  };

  const handleDeleteConfirmation = (leaveId) => {
    setDeleteConfirmation(leaveId);
  };

  const handleDeleteCancel = (event) => {
    setDeleteConfirmation(null);
  };

  const handleDeleteLeave = async (leaveId) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}companyleave/${leaveId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          date: new Date().toISOString().split("T")[0],
          createdby: userDetails.firstname,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.firstname,
          modifiedat: new Date().toISOString(),
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setGetDataFromApi(true);
        showToast("success", leaveManagementError.deletedleaveError);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
      setLeaves((prevLeaves) =>
        prevLeaves.filter((leave) => leave.id !== leaveId)
      );
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
      setGetDataFromApi(true);
    }
    setDeleteConfirmation(null);
  };

  const handleDialogClick = (event) => {
    event.stopPropagation();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsEdit(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsEdit(false);
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const searchFields = ["date", "leave_type"];
    const filteredResults = masterDataLeaves.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLeaves(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataLeaves]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  return (
    <React.Fragment>
      <AddHoliday
        onLeaveAdded={handleLeaveAdded}
        showForm={showForm}
        setShowForm={setShowForm}
      />
      <Mui.Grid container sx={{ paddingTop: 3 }}>
        <Mui.Grid item xs={5}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            value={`01/04/${taxYear.substring(0, 4)} To 31/03/${taxYear.slice(
              -4
            )}`}
            options={leaveTaxYear}
            style={{
              width: 350,
              marginLeft: 5,
            }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            onChange={(event, value) => {
              if (value) {
                setLeaves([]);
                setTaxYear(value.label);
                setTaxYearStartDate(value.label.substring(0, 4) + "-04-01");
                setTaxYearEndDate(value.label.slice(-4) + "-03-31");
                setGetDataFromApi(true);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                label={taxYear}
                sx={{ background: themes.whiteColor }}
                placeholder={leaveManagamentPlaceholder.taxyearLeave}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <CalendarMonthIcon
                        style={{ margin: "0 8px", color: "#a6a6a6" }}
                      />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={7}>
          {!showForm && (
            <Mui.Box
              sx={{
                position: "relative",
                textAlign: "right",
                paddingRight: 1.5,
              }}
            >
              <CustomButton
                actionFuntion={handleShowForm}
                actionName="Add Holiday"
                typeName="button"
              />
            </Mui.Box>
          )}
        </Mui.Grid>
      </Mui.Grid>
      <form>
        <Mui.TableContainer sx={tableContainerSx} ref={tableContainerRef}>
          {leaves?.length !== 0 ? (
            <Mui.Table>
              <Mui.TableHead sx={tableHeaderSx}>
                <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
                  {holidayHeader?.map?.((value) => (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {value?.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {leaves
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((leave) => (
                    <Mui.TableRow
                      key={leave.id}
                      maxWidth="xl"
                      align="left"
                      sx={tableRowSx}
                    >
                      <React.Fragment>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {isEdit && editLeaveId === leave.id ? (
                            <Mui.Grid>
                              <Mui.TextField
                                size="small"
                                id={`from-date-${leave.id}`}
                                type="date"
                                value={editedFromDate}
                                {...register(`fromDate-${leave.id}`, {
                                  required: "Date is required.",
                                })}
                                onChange={(e) =>
                                  setEditedFromDate(e.target.value)
                                }
                                fullWidth
                              />
                              {errors[`fromDate-${leave.id}`] && (
                                <Mui.Typography color="error" variant="body2">
                                  Date is required.
                                </Mui.Typography>
                              )}
                            </Mui.Grid>
                          ) : (
                            leave?.date
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {isEdit && editLeaveId === leave.id ? (
                            <Mui.Grid>
                              <Mui.TextField
                                size="small"
                                placeholder="Please enter holiday name"
                                {...register(`holidayname-${leave.id}`, {
                                  required: "Holiday name is required",
                                  minLength: {
                                    value: 3,
                                    message: "Minimum length is 3 characters",
                                  },
                                  maxLength: {
                                    value: 30,
                                    message: "Maximum length is 30 characters",
                                  },
                                })}
                                value={editedLeaveType}
                                onKeyPress={handleKeyDown}
                                onChange={(e) =>
                                  setEditedLeaveType(e.target.value)
                                }
                                fullWidth
                              />
                              {errors[`holidayname-${leave.id}`] && (
                                <Mui.Typography color="error" variant="body2">
                                  {errors[`holidayname-${leave.id}`].message}
                                </Mui.Typography>
                              )}
                            </Mui.Grid>
                          ) : (
                            leave?.leave_type
                          )}
                        </Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            fontSize: tableCellFontSize,
                          }}
                        >
                          {isEdit && editLeaveId === leave.id ? (
                            <React.Fragment>
                              <Mui.IconButton>
                                {buttonLoader ? (
                                  <Mui.CircularProgress
                                    sx={{
                                      color: themes.primaryIndicator,
                                    }}
                                    size={24}
                                  />
                                ) : (
                                  <Done
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                    onClick={handleSubmit(handleSaveEdit)}
                                  />
                                )}
                              </Mui.IconButton>
                              <Mui.IconButton onClick={handleCancelEdit}>
                                <Cancel
                                  sx={{
                                    color: themes.redColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Mui.IconButton
                                onClick={(event) => {
                                  setIsEdit(true);
                                  handleLeaveEdit(leave);
                                }}
                              >
                                <Edit
                                  sx={{
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={(event) => {
                                  setIsEdit(false);
                                  handleDeleteConfirmation(leave.id);
                                }}
                              >
                                <Delete
                                  sx={{
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </React.Fragment>
                          )}
                        </Mui.TableCell>
                      </React.Fragment>
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          ) : loading ? (
            <div>
              <Loader info="Loading..." />
            </div>
          ) : leaves.length === 0 ? (
            <Mui.Alert severity="info" sx={{ mt: 2 }}>
              No data available
            </Mui.Alert>
          ) : null}
        </Mui.TableContainer>
      </form>
      {leaves?.length > 10 && (
        <TablePagination
          sx={{ marginRight: 5 }}
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={leaves?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <GlobalDialogBox
        handleCloseDialog={handleDeleteCancel}
        open={deleteConfirmation}
        title="Delete Holiday"
        onClick={handleDialogClick}
      >
        <Mui.Grid container>
          <Mui.Grid item xs={12}>
            <Mui.Typography fontSize={24}>
              Are you sure you want to delete this leave?
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={8}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.Grid item xs={2}>
            <CustomButton
              actionFuntion={() => handleDeleteLeave(deleteConfirmation)}
              actionName="Yes"
              typeName="button"
              disableAction={
                deleteConfirmation && buttonLoader ? buttonLoader : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};

export default Holidays;
