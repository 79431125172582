import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  InputLabel,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useSelector } from "react-redux";

import { CustomButton } from "components/CustomButton";
import { showToast } from "components/Status";

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { domain } = useSelector((state) => state.tokenReducer);
  const [userEmail, setUserEmail] = useState("");
  const [move, setMove] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmit = async (data) => {
    try {
      setButtonLoader(true);
      setUserEmail(data?.email);
      const response = await fetch(`${domain}password/reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data?.email,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res?.message);
        setMove(false);
        reset();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const ResetPassword = () => {
    setMove(true);
    setValue("email", userEmail);
  };

  return (
    <div className="fullscreen-bg">
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {move ? (
          <Card
            content="center"
            sx={{
              minWidth: "30%",
              height: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
              boxShadow: 5,
              borderRadius: 5,
              textAlign: "center",
              marginTop: 30,
            }}
          >
            {" "}
            <CardContent
              sx={{
                marginTop: 5,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography
                fontSize={20}
                sx={{
                  marginLeft: -30,
                  marginTop: -3,
                  fontWeight: "bold",
                }}
              >
                <>
                  <AccountBoxIcon color="primary" fontSize={"large"} /> {""}
                  Can't log in? <br></br>
                  <InputLabel sx={{ marginLeft: 19 }}>
                    Restore access to your account
                  </InputLabel>
                </>
              </Typography>
              <br></br>
              <InputLabel sx={{ marginRight: 20, fontWeight: "bold" }}>
                We will send a recovery link to{" "}
              </InputLabel>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField
                      sx={{
                        marginTop: 2,
                        minWidth: 400,
                        marginLeft: 1,
                        marginRight: "auto",
                      }}
                      size="medium"
                      label="Email"
                      placeholder="Please Enter E-mail"
                      type="text"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge color="secondary" badgeContent={0}>
                              <MailIcon color="primary" />
                            </Badge>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Grid item xs={12} sx={{ marginTop: 4 }}>
                      <Grid container justifyContent="center">
                        <CustomButton
                          actionName="Reset Password"
                          typeName="submit"
                          disableAction={buttonLoader}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        ) : (
          <div className="fullscreen-bg">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                content="center"
                sx={{
                  minWidth: "30%",
                  height: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                  boxShadow: 5,
                  borderRadius: 5,
                  textAlign: "center",
                  marginTop: 30,
                }}
              >
                <CardContent
                  sx={{
                    marginTop: 2,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography fontWeight={"bold"} fontSize={20}>
                        Check Your Email <br></br>
                      </Typography>
                      <br></br>
                      <InputLabel>
                        We've sent password reset instruction for
                        <Typography
                          fontWeight={"bold"}
                          fontSize={17}
                          variant="h6"
                        >
                          {userEmail}
                        </Typography>
                        if you haven't received this email within a few minutes,{" "}
                        <br></br>please check your spam folder
                      </InputLabel>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12} xl={11.5} sx={{ marginTop: 4 }}></Grid>
                      <Grid container spacing={0} justifyContent={"center"}>
                        <Grid item xs={6} md={3} sx={{ marginTop: 2 }}>
                          <Button
                            sx={{
                              borderRadius: 2,
                              color: "primary.contrastText",
                              backgroundColor: "primary.main",
                              "&:hover": {
                                backgroundColor: "primary.main",
                                color: "primary.contrastText",
                              },
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            href="/auth/admin/"
                          >
                            Login
                          </Button>
                        </Grid>

                        <Grid item xs={6} md={3} sx={{ marginTop: 2 }}>
                          <Button
                            sx={{
                              borderRadius: 2,
                              color: "secondary.contrastText",
                              backgroundColor: "secondary.main",
                              "&:hover": {
                                backgroundColor: "secondary.main",
                                color: "secondary.contrastText",
                              },
                            }}
                            variant="contained"
                            color="secondary"
                            // type="submit"
                            // href="/auth/forgot-password/"
                            onClick={ResetPassword}
                          >
                            Resend
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
