import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import { MoreVert, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomButton";
import { Loader } from "components/Loader";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import sessionHandling from "services/utils/notificationUtils";
import { designationHeader } from "pages/Administration/AdministrationHeaders";
import {
  themes,
  tableContainerSx,
  tableHeaderSx,
  tableRowSx,
  tableCellFontSize,
  scrollTop,
} from "services/constants";

export const Designation = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [fetchDesignationData, setFetchDesignationData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [designationData, setDesignationData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState([]);
  const [etype, setEtype] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const tableContainerRef = useRef(null);
  useEffect(() => {
    scrollTop(tableContainerRef);
  }, [page]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [masterDesignationList, setMasterDesignationList] = useState([]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const checkExistingDesignation = useCallback(
    async (designation) => {
      try {
        const response = await fetch(
          `${domain}check/designation?designation_name=${designation}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "Designation is already exists";
          } else {
            return true;
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const addDesignation = useCallback(
    async (designation, description) => {
      const value = await checkExistingDesignation(designation);
      if (value) {
        try {
          const response = await fetch(`${domain}designation/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              designation: designation,
              description: description,
            }),
          });

          const res = await response.json();
          if (response.ok) {
            showToast("success", "Designation added successfully.");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setFetchDesignationData(true);
        }
      }
    },
    [checkExistingDesignation, domain, token]
  );

  const updateDesignation = useCallback(
    async (designation, description) => {
      const value = await checkExistingDesignation(designation);
      if (value) {
        try {
          const response = await fetch(`${domain}designation/${edit?.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              designation: designation,
              description: description,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            showToast("success", "Designation updated successfully.");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setFetchDesignationData(true);
        }
      }
    },
    [checkExistingDesignation, domain, edit?.id, token]
  );

  const removeDesignation = useCallback(async () => {
    try {
      const response = await fetch(`${domain}designation/${edit?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: "true",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Designation deleted successfully");
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setFetchDesignationData(true);
    }
  }, [domain, edit?.id, token]);

  const fetchDesignationDatas = useCallback(async () => {
    setLoader(true);
    try {
      const response = await fetch(`${domain}designation/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setDesignationData(res);
        setMasterDesignationList(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
    setLoader(false);
  }, [domain, token]);

  useEffect(() => {
    setFetchDesignationData(true);
  }, [setFetchDesignationData]);

  useEffect(() => {
    if (fetchDesignationData === true) {
      fetchDesignationDatas();
      setFetchDesignationData(false);
      setButtonLoader(false);
      setOpenDialog(false);
      setEtype("");
    }
  }, [fetchDesignationData, fetchDesignationDatas]);
  useEffect(() => {
    if (etype !== "") {
      setOpenDialog(true);
    }
  }, [etype]);

  useEffect(() => {
    const searchFields = ["designation", "description"];
    const filteredResults = masterDesignationList.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setDesignationData(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDesignationList]);

  const moveToAddDesignation = () => {
    setEtype("Add");
  };

  return loader === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <EtypeDialog
        type={etype}
        setEtype={setEtype}
        edit={edit}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        addDesignation={addDesignation}
        updateDesignation={updateDesignation}
        removeDesignation={removeDesignation}
        checkExistingDesignation={checkExistingDesignation}
        buttonLoader={buttonLoader}
        setButtonLoader={setButtonLoader}
      />
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingTop: 2,
          paddingRight: 1,
        }}
      >
        <CustomButton
          actionFuntion={moveToAddDesignation}
          actionName="Add Designation"
          typeName="button"
        />
      </Mui.Grid>
      <Mui.TableContainer sx={tableContainerSx} ref={tableContainerRef}>
        <Mui.Table>
          <Mui.TableHead sx={tableHeaderSx}>
            <Mui.TableRow maxWidth="xl" align="left" sx={tableRowSx}>
              {designationHeader?.map?.((value) => (
                <Mui.TableCell
                  sx={{
                    color: themes.blackColor,
                    fontWeight: "bold",
                    fontSize: tableCellFontSize,
                  }}
                >
                  {value?.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {designationData?.length === 0 ? (
              <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                No data found
              </Mui.Alert>
            ) : (
              designationData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item) => (
                  <Mui.TableRow
                    key={item?.id}
                    maxWidth="xl"
                    align="left"
                    sx={tableRowSx}
                  >
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {item?.designation ? item?.designation : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontSize: tableCellFontSize,
                      }}
                    >
                      {item?.description ? item?.description : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontSize: tableCellFontSize,
                      }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setEdit([]);
                          setEdit(item);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Edit");
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(null);
                            setEtype("Delete");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      {designationData?.length > 10 ? (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[25, 50, 75, 100]}
          count={designationData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </React.Fragment>
  );
};

export default function EtypeDialog({
  type,
  setEtype,
  edit,
  openDialog,
  setOpenDialog,
  addDesignation,
  updateDesignation,
  removeDesignation,
  checkExistingDesignation,
  buttonLoader,
  setButtonLoader,
}) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState({});

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setEtype("");
    setValue("designation", "");
    setValue("description", "");
    reset();
  }, [reset, setEtype, setOpenDialog, setValue]);

  useEffect(() => {
    if (type === "Add") {
      setValue("designation", "");
      setValue("description", "");
      reset();
    } else if (type === "Edit") {
      setValue("designation", edit?.designation);
      setValue("description", edit?.description);
    } else if (type === "Delete") {
      setValue("designation", "");
      setValue("description", "");
      reset();
    }
  }, [edit?.description, edit?.designation, reset, setValue, type]);

  useEffect(() => {
    const submitData = async () => {
      if (type === "Add") {
        await addDesignation(data?.designation, data?.description);
        handleClose();
      } else if (type === "Edit") {
        await updateDesignation(data?.designation, data?.description);
        handleClose();
      } else if (type === "Delete") {
        await removeDesignation();
        handleClose();
      }
    };
    if (buttonLoader) {
      submitData();
    }
  }, [
    addDesignation,
    buttonLoader,
    data?.description,
    data?.designation,
    handleClose,
    removeDesignation,
    type,
    updateDesignation,
  ]);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    setTimeout(() => null, 3000);
    if (data) {
      setData(data);
    }
  };

  const checkSpecialChar = (e) => {
    if (!/[a-zA-Z ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkSpecialCharWithDigits = (e) => {
    if (!/[a-zA-Z\d ]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <form>
      <GlobalDialogBox
        key={type}
        handleCloseDialog={handleClose}
        open={openDialog}
        title={
          type === "Delete"
            ? "Delete Designation"
            : type === "Add"
            ? "Add Designation"
            : "Edit Designation"
        }
      >
        {type === "" ? null : type === "Delete" ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                Do you want to remove the designation?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClose}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  key={buttonLoader}
                  actionFuntion={handleSubmit(onSubmit)}
                  actionName="Yes"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-start" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Designation</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  placeholder="Enter the designation"
                  {...register("designation", {
                    required: "Designation is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      designation: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else if (type === "Add") {
                          return checkExistingDesignation(fieldValue.trim());
                        } else if (edit?.designation !== fieldValue) {
                          return checkExistingDesignation(fieldValue.trim());
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  onKeyPress={checkSpecialChar}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.designation)}
                  helperText={errors.designation?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-start" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Description</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Here, you can add more infor about the roles and responsibilities the designation holds.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  multiline={true}
                  rows={3}
                  placeholder="Enter the description"
                  {...register("description", {
                    required: "Description is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      designationDescription: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 128 },
                  }}
                  onKeyPress={checkSpecialCharWithDigits}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message || ""}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={handleClose}
                actionName="Cancel"
                typeName="submit"
              />
              &nbsp;&nbsp;
              <CustomButton
                key={buttonLoader}
                actionFuntion={handleSubmit(onSubmit)}
                actionName={type === "Add" ? "Add" : "Update"}
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Stack>
          </>
        )}
      </GlobalDialogBox>
    </form>
  );
}
