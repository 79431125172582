import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import { themes } from "services/constants";
import {
  Grid,
  Card,
  Button,
  CardContent,
  TextField,
  IconButton,
  Badge,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLocation } from "services/Redux/userToken";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@material-ui/core";
import { changePassword } from "services/constants";
import { showToast } from "components/Status";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomButton";
export const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  dispatch(setCurrentLocation("ChangePassword"));
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmnewpassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCnfmNewPassword, setShowCnfmNewPassword] = useState(false);
  const [passwordEqualCheck, setPasswordEqualCheck] = useState("");
  const [buttonValidation, setButtonValidation] = useState(true);
  const [lowercase, setLowercase] = useState("");
  const [uppercase, setUppercase] = useState("");
  const [number, setNumber] = useState("");
  const [passwordLength, setPasswordLength] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleClickShowNewPwd = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowCnfmNewPwd = () => {
    setShowCnfmNewPassword(!showCnfmNewPassword);
  };

  useEffect(() => {
    const changePasswordRequest = async () => {
      try {
        setButtonLoader(true);
        const response = await fetch(`${domain}change-password/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            old_password: oldpassword,
            new_password: newpassword,
          }),
        });
        if (response.ok) {
          setOldPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setPasswordEqualCheck("");
          showToast("success", changePassword.successAlert);
          setButtonValidation(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          showToast("error", changePassword.failureAlert);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    };
    if (callApi) {
      changePasswordRequest();
      setCallApi(false);
    }
  }, [callApi, domain, history, newpassword, oldpassword, token]);

  const checkValidation = () => {
    if (
      confirmnewpassword !== "" &&
      oldpassword !== "" &&
      newpassword !== "" &&
      confirmnewpassword === newpassword &&
      newpassword.match(/[A-Z]/g) &&
      newpassword.match(/[A-Z]/g) &&
      newpassword.match(/[0-9]/g)
    ) {
      setButtonValidation(false);
    } else {
      setButtonValidation(true);
    }
  };

  const handleKeyDown = () => {
    newpassword.match(/[a-z]/g) ? setLowercase(true) : setLowercase(false);
    newpassword.match(/[A-Z]/g) ? setUppercase(true) : setUppercase(false);
    newpassword.match(/[0-9]/g) ? setNumber(true) : setNumber(false);
    newpassword.length >= 8
      ? setPasswordLength(true)
      : setPasswordLength(false);
    if (
      newpassword.match(/[a-z]/g) &&
      newpassword.match(/[A-Z]/g) &&
      newpassword.match(/[0-9]/g) &&
      newpassword.length >= 8
    ) {
      setLowercase("");
      setUppercase("");
      setNumber("");
      setPasswordLength("");
    }
  };

  const UpdatePassword = () => {
    setCallApi(true);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ paddingTop: 12 }}
      >
        <Grid container spacing={2}>
          {" "}
          <Col className="order-xl-1" xl="3"></Col>
          <Col className="order-xl-1" xl="6">
            <Card sx={{ backgroundColor: themes.formBackground }}>
              <CardContent>
                <h2 className="text-center">Change Your Password</h2>
                <Divider variant="middle" />
                <br />
                <form>
                  <div className="pl-lg-4">
                    <Grid container spacing={2}>
                      <Grid lg="2"></Grid>
                      <Grid lg="8" sx={{ paddingBottom: 2 }}>
                        <Typography>Current Password</Typography>
                        <TextField
                          sx={{
                            width: 400,
                            "@media (min-width: 1224px)": {
                              width: 400,
                            },

                            "@media (min-width: 1824px)": {
                              width: 465,
                            },
                            background: themes.whiteColor,
                          }}
                          value={oldpassword}
                          placeholder="Old Password"
                          type={"text"}
                          onKeyUp={checkValidation}
                          onChange={(e) => setOldPassword(e.target.value)}
                          inputProps={{
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                      </Grid>
                      <Grid lg="2"></Grid>
                      <Grid lg="2"></Grid>
                      <Grid lg="8" sx={{ paddingBottom: 2 }}>
                        <Typography>New Password</Typography>
                        <TextField
                          sx={{
                            width: 400,

                            "@media (min-width: 1224px)": {
                              width: 400,
                            },

                            "@media (min-width: 1824px)": {
                              width: 465,
                            },
                            background: themes.whiteColor,
                          }}
                          value={newpassword}
                          placeholder="New Password"
                          type={showNewPassword ? "text" : "password"}
                          onKeyUpCapture={handleKeyDown}
                          onChange={(e) => setNewPassword(e.target.value)}
                          onKeyUp={checkValidation}
                          autoComplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Badge color="primary" badgeContent={0}>
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPwd}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Badge>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {lowercase ? (
                          <Typography sx={{ color: themes.greenColor }}>
                            √ A lowercase letter <br />
                          </Typography>
                        ) : lowercase === "" ? (
                          ""
                        ) : (
                          <Typography sx={{ color: themes.redColor }}>
                            ☒ A lowercase letter <br />
                          </Typography>
                        )}
                        {uppercase ? (
                          <Typography sx={{ color: themes.greenColor }}>
                            √ A uppercase letter <br />
                          </Typography>
                        ) : uppercase === "" ? (
                          ""
                        ) : (
                          <Typography sx={{ color: themes.redColor }}>
                            ☒ A uppercase letter <br />
                          </Typography>
                        )}
                        {number ? (
                          <Typography sx={{ color: themes.greenColor }}>
                            √ A number <br />
                          </Typography>
                        ) : number === "" ? (
                          ""
                        ) : (
                          <Typography sx={{ color: themes.redColor }}>
                            ☒ A number <br />
                          </Typography>
                        )}
                        {passwordLength ? (
                          <Typography sx={{ color: themes.greenColor }}>
                            √ Minimum 8 characters
                            <br />
                          </Typography>
                        ) : passwordLength === "" ? (
                          ""
                        ) : (
                          <Typography sx={{ color: themes.redColor }}>
                            ☒ Minimum 8 characters <br />
                          </Typography>
                        )}
                      </Grid>
                      <Grid lg="2"></Grid>
                      <Grid lg="2"></Grid>
                      <Grid lg="8" sx={{ paddingBottom: 2 }}>
                        <Typography
                          className="form-control-Typography"
                          htmlFor="input-username"
                          style={{ marginTop: 10 }}
                        >
                          Confirm New Password
                        </Typography>
                        <TextField
                          sx={{
                            width: 400,
                            "@media (min-width: 1224px)": {
                              width: 400,
                            },

                            "@media (min-width: 1824px)": {
                              width: 465,
                            },
                            background: themes.whiteColor,
                          }}
                          value={confirmnewpassword}
                          placeholder="Confirm New Password"
                          type={showCnfmNewPassword ? "text" : "password"}
                          onChange={(e) => {
                            if (e.target.value === newpassword) {
                              setPasswordEqualCheck(false);
                            } else {
                              setPasswordEqualCheck(true);
                            }
                            setConfirmNewPassword(e.target.value);
                          }}
                          onKeyUp={checkValidation}
                          autoComplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Badge color="primary" badgeContent={0}>
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowCnfmNewPwd}
                                    edge="end"
                                  >
                                    {showCnfmNewPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Badge>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {passwordEqualCheck === true ? (
                          <h4 style={{ color: themes.redColor }}>
                            Use same Password
                          </h4>
                        ) : passwordEqualCheck === "" ? (
                          ""
                        ) : (
                          <h4 style={{ color: themes.greenColor }}>
                            Password Matched
                          </h4>
                        )}
                      </Grid>
                      <Grid lg="2"></Grid>
                      <Grid lg="8"></Grid>
                      <Grid lg="4">
                        <div style={{ paddingLeft: 0 }}>
                          {buttonLoader ? (
                            <CustomButton
                              actionName="Set Password"
                              typeName="submit"
                              disableAction={buttonLoader}
                            />
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => UpdatePassword()}
                              disabled={buttonValidation}
                              sx={{
                                alignItems: "center",
                                background: themes.primaryButton,
                                textTransform: "capitalize",
                                "&:hover": {
                                  backgroundColor: themes.primaryButton,
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  color: buttonValidation
                                    ? themes.lightGray
                                    : themes.blackColor,
                                }}
                              >
                                Set Password
                              </Typography>
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="3"></Col>
        </Grid>
      </Grid>
    </>
  );
};
