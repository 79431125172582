import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BackArrow } from "components/BackArrow";
import { Loader } from "components/Loader";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { summaryDetails } from "../PayRollHeaders";
import { getStatusColor } from "components/Status";
import { CustomButton } from "components/CustomButton";
import { useSelector } from "react-redux";
import { showToast } from "components/Status";
import sessionHandling from "services/utils/notificationUtils";
import { ConstStructure } from "./CostStructure";
import { OverTime } from "./OverTime";
import { SalaryRevision } from "./SalaryRevision";

export const ViewPayRun = ({
  setViewPayRunScreen,
  companyId,
  companyName,
  viewPayrunDetails,
  setViewPayRunDetails,
  reCallApi,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [summaryName, setSummaryName] = useState("Cost Structure");
  const [payrollCost, setPayrollCost] = useState(0);
  const [employeeNetPay, setEmployeeNetPay] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [status, setStatus] = useState(viewPayrunDetails.status);
  const [loading, setLoading] = useState(false);
  const [reloadUserPayrollDetails, setReloadUserPayrollDetails] =
    useState(false);
  const handleSummaryDetails = (event, data) => {
    setSummaryName(data?.label);
  };

  const actionButtonBasedOnStatusWithInitiate = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${domain}payrun/initiate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setStatus("InProgress");
        showToast("success", res.message);
        setReloadUserPayrollDetails(true);
        setLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const actionButtonBasedOnStatusWithSubmit = async () => {
    try {
      const response = await fetch(`${domain}payrun/status-update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
          status: "Submitted",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setViewPayRunScreen(false);
        reCallApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const renderComponent = () => {
    switch (summaryName) {
      case "Cost Structure":
        return (
          <ConstStructure
            viewPayrunDetails={viewPayrunDetails}
            setPayrollCost={setPayrollCost}
            setEmployeeNetPay={setEmployeeNetPay}
            setTotalDeduction={setTotalDeduction}
            setTotalWorkingHours={setTotalWorkingHours}
            companyId={companyId}
            reloadUserPayrollDetails={reloadUserPayrollDetails}
            setReloadUserPayrollDetails={setReloadUserPayrollDetails}
          />
        );
      case "Over Time":
        return <OverTime viewPayrunDetails={viewPayrunDetails} />;
      case "Salary Revision":
        return <SalaryRevision viewPayrunDetails={viewPayrunDetails} />;
      default:
        break;
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        <Mui.Grid xs={12}>
          <Mui.Stack direction="Row">
            <BackArrow
              actionFunction={() => {
                reCallApi(true);
                setViewPayRunScreen(false);
              }}
            />
            <Mui.Typography
              sx={{ paddingTop: 1, fontSize: 25, fontWeight: "bold" }}
            >
              Pay runs - {companyName} -{summaryName}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingTop: 1 }}>
        {summaryName === "Cost Structure" ? (
          <Mui.Grid xs={5}>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={8}>
                Period :
                {viewPayrunDetails.start_date +
                  " to " +
                  viewPayrunDetails.end_date}
              </Mui.Grid>
              <Mui.Grid xs={4}>{totalWorkingHours} Working days</Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container component={Mui.Paper} elevation={10}>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
              >
                <Mui.Typography sx={{ paddingLeft: 2 }}>
                  {payrollCost}
                </Mui.Typography>
                <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                  Payroll cost
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
              >
                <Mui.Typography sx={{ paddingLeft: 2 }}>
                  {employeeNetPay}
                </Mui.Typography>
                <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                  Employee's net pay
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
              >
                <Mui.Typography sx={{ paddingLeft: 2 }}>
                  {totalDeduction}
                </Mui.Typography>
                <Mui.Typography sx={{ paddingTop: 2, paddingLeft: 2 }}>
                  Total deductions
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        ) : (
          <Mui.Grid xs={5}>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography>Payroll Period</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography>Total Working Days</Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container component={Mui.Paper} elevation={5}>
              <Mui.Grid
                xs={6}
                sx={{ borderRight: `4px solid ${themes.sidebarDivider}` }}
              >
                <Mui.Typography sx={{ textAlign: "center", padding: 3 }}>
                  {viewPayrunDetails.start_date +
                    "-" +
                    viewPayrunDetails.end_date}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ textAlign: "center", padding: 3 }}>
                  {totalWorkingHours}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        )}
        <Mui.Grid xs={7}>
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 1,
            }}
          >
            <Mui.Box
              sx={{
                width: 120,
                height: 15,
                backgroundColor: getStatusColor(status),
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themes.backgroundWhite,
                p: 2,
                fontSize: 13,
              }}
            >
              {status}
            </Mui.Box>
          </Mui.Grid>
          {/* {loading && (
            <Mui.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
            >
              <Loader />
            </Mui.Box>
          )} */}
          {status === "Draft" ? (
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  actionButtonBasedOnStatusWithInitiate();
                }}
                actionName="Initiate Payroll"
                typeName="submit"
              />
            </Mui.Grid>
          ) : status === "Rejected" ? (
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  actionButtonBasedOnStatusWithInitiate();
                  viewPayrunDetails.status = "InProgress";
                  setViewPayRunDetails(viewPayrunDetails);
                }}
                actionName="Rerun Payroll"
                typeName="button"
              />
            </Mui.Grid>
          ) : (
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 1,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  actionButtonBasedOnStatusWithInitiate();
                }}
                actionName="Rerun Payroll"
                typeName="button"
              />
              &nbsp;&nbsp;
              <CustomButton
                actionFuntion={() => {
                  actionButtonBasedOnStatusWithSubmit();
                }}
                actionName="Submit"
                typeName="button"
              />
            </Mui.Grid>
          )}
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid xs={9}></Mui.Grid>
        <Mui.Grid xs={3}>
          <CustomAutoComplete
            dataList={summaryDetails}
            selectedValue={summaryName}
            updateDataFunction={handleSummaryDetails}
            placeHolder="Please select the summary"
            fullWith
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>{renderComponent()}</Mui.Grid>
    </React.Fragment>
  );
};
