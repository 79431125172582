import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Device } from "components/Navbars/DeviceStatus";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { showToast } from "components/Status";
import * as Mui from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import { themes } from "services/constants";
import { ListView } from "./ListView";
import { CardView } from "./CardView";
import {
  KeyboardArrowDown,
  CreditCard,
  List,
  AccountBalance,
} from "@mui/icons-material";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { defaultAllSelection } from "services/constants";
import { setReloadHRMSApi } from "services/Redux/leaveManagement";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/BackArrow";

export const Status = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  let reloadHRMSApi = useSelector((state) => state.leaveReducer.reloadHRMSApi);
  const [loading, setLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [shiftId, setshiftId] = useState(defaultAllSelection);
  const [expandedSubMenuAnchorEl, setExpandedSubMenuAnchorEl] = useState(null);
  const [getShiftCategory, setGetShiftCategory] = useState(true);
  const [shiftCatagoryName, setShiftCatagoryName] = useState("");
  const [shiftData, setShiftData] = useState([]);
  const [shiftName, setShiftName] = useState("");
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [deviceOffine, setDeviceOffline] = useState(false);
  const [date, setFilterDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [view, setView] = useState("Card");
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(0);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = departmentData.filter((data) =>
    data.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    dispatch(setReloadHRMSApi(true));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentData([]);
          const departmentDataWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          const allDepartment = {
            id: defaultAllSelection,
            label: "All",
            departmentname: "All",
          };
          setDepartmentData([allDepartment, ...departmentDataWithLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  const fetchShiftCatagoryDetails = async (id, dep_name) => {
    try {
      const response = await fetch(
        `${domain}shift_categories/?department_id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      const allShifts = {
        id: defaultAllSelection,
        label: "All",
        name: "All",
      };
      setShiftName(allShifts?.label);
      setshiftId(allShifts?.id);
      if (response.ok) {
        const fetchShifts = res?.map((value) => value?.shifts);
        let shiftDataWithLabel = [];
        if (dep_name !== "All") {
          shiftDataWithLabel = fetchShifts[0]?.map((obj) => ({
            ...obj,
            label: obj?.name,
          }));
          setShiftCatagoryName(res[0]?.name);
          if (shiftDataWithLabel?.length !== 0) {
            setShiftData([allShifts, ...shiftDataWithLabel]);
          } else {
            setShiftData([allShifts]);
          }
        } else {
          setShiftData([allShifts]);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error?.message);
    }
  };

  useEffect(() => {
    const fetchShiftCatagory = async () => {
      try {
        const response = await fetch(
          `${domain}shift_categories/?department_id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        const allShifts = {
          id: defaultAllSelection,
          label: "All",
          name: "All",
        };
        setShiftName(allShifts?.label);
        setshiftId(allShifts?.id);
        if (response.ok) {
          const fetchShifts = res?.map((value) => value?.shifts);
          let shiftDataWithLabel = [];
          if (departmentName !== "All") {
            shiftDataWithLabel = fetchShifts[0]?.map((obj) => ({
              ...obj,
              label: obj.name,
            }));
            setShiftCatagoryName(res[0]?.name);
            setShiftCatagoryName(res[0]?.name);
            setShiftData([allShifts, ...shiftDataWithLabel]);
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (getShiftCategory) {
      fetchShiftCatagory();
      setGetShiftCategory(false);
    }
  }, [departmentId, departmentName, dispatch, domain, getShiftCategory, token]);

  const fetchStatus = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${domain}checkinfilter/?department_id=${departmentId}&date=${date}&shift_id=${shiftId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        if (response.status === 202) {
          setDeviceOffline(true);
        } else {
          dispatch(setReloadHRMSApi(false));
          setDepartmentMembers(res);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setLoading(false);
    }
  }, [date, departmentId, dispatch, domain, shiftId, token]);

  useEffect(() => {
    if (reloadHRMSApi) {
      fetchStatus();
    }
  }, [fetchStatus, reloadHRMSApi]);

  const status = [
    { name: "All", count: departmentMembers?.overall_count ?? "0" },
    { name: "Active", count: departmentMembers?.active_count ?? "0" },
    { name: "Break", count: departmentMembers?.break_count ?? "0" },
    { name: "Leave", count: departmentMembers?.leave_count ?? "0" },
  ];
  const [selectedValue, setSelectedValue] = useState("All");
  const [filterDepartMemberList, setFilterDepartMemberList] = useState([]);

  useEffect(() => {
    const filteredList =
      selectedValue === "All"
        ? departmentMembers?.user_status_details
        : departmentMembers?.user_status_details?.filter((value) =>
            selectedValue === "Leave"
              ? value?.type === "Leave" || value?.type === "Halfday"
              : value?.type === selectedValue
          );

    setFilterDepartMemberList(filteredList ? filteredList : []);
    setMasterDataForSearch(filteredList ? filteredList : []);
  }, [departmentMembers?.user_status_details, selectedValue]);

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setFilterDepartMemberList(FilterDockets());
  }, [globalSearchValue, masterDataForSearch]);

  const summarizeExport = () => {
    let dataPreparation = [];
    departmentMembers?.user_status_details.forEach((element) => {
      let overAllData = {
        user_name: element.user_name,
        user_code: element.user_code,
        check_in_time_formatted: element.check_in_time_formatted,
        total_break_time: element.total_break_time,
        check_out_time_formatted: element.check_out_time_formatted,
        total_work_time: element.total_work_time,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const department = ["Department Name"];
    const shiftCategory = ["ShiftCategory Name"];
    const shift = ["Shift Name"];
    const date = ["Date"];
    department.push(departmentName);
    shiftCategory.push(shiftCatagoryName);
    shift.push(shiftName);
    const items = dataPreparation;
    const TableHead = ["Username"];
    TableHead.push("User Code");
    TableHead.push("Check In");
    TableHead.push("Breaks");
    TableHead.push("Check Out");
    TableHead.push("Working Hours");

    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(",");
    csv.unshift(date.join(","));
    csv.unshift(shift.join(","));
    csv.unshift(shiftCategory.join(","));
    csv.unshift(department.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "SummarizedReported.csv";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      {deviceOffine ? (
        ""
      ) : (
        <>
          <Mui.Grid item sx={{ paddingTop: 1 }}>
            <BackArrow
              actionFunction={() => history.push("/admin/teamzone/attendance")}
            />
          </Mui.Grid>
          <Mui.Grid container xs={12}>
            <Mui.Grid item xs={3}>
              <Mui.Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: 270,
                  height: 120,
                  backgroundColor: "#FFF8E5",
                }}
              >
                <Mui.Grid container sx={{ paddingLeft: 2 }}>
                  <Mui.Grid
                    xs={2}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: "#FFD83A",
                      borderRadius: "50%",
                    }}
                  >
                    <AccountBalance
                      style={{ color: "white", alignItems: "center" }}
                      fontSize="medium"
                    />
                  </Mui.Grid>
                  &nbsp; &nbsp;
                  <Mui.Grid xs={7}>
                    <Mui.Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "capitalize",
                      }}
                    >
                      {departmentName?.length > 10 ? (
                        <Mui.Tooltip title={departmentName}>
                          <span>{`${departmentName.substring(0, 10)}..`}</span>
                        </Mui.Tooltip>
                      ) : (
                        departmentName
                      )}
                    </Mui.Typography>
                    <Mui.Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {shiftCatagoryName?.length > 10 ? (
                        <Mui.Tooltip title={shiftCatagoryName}>
                          <span>{`${shiftCatagoryName.substring(
                            0,
                            10
                          )}..`}</span>
                        </Mui.Tooltip>
                      ) : (
                        shiftCatagoryName
                      )}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid xs={2} sx={{ paddingRight: 2 }}>
                    <Mui.IconButton
                      onClick={(e) =>
                        setExpandedSubMenuAnchorEl(e.currentTarget)
                      }
                    >
                      <KeyboardArrowDown />
                    </Mui.IconButton>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Box>

              <Mui.Popover
                anchorEl={expandedSubMenuAnchorEl}
                open={Boolean(expandedSubMenuAnchorEl)}
                onClose={() => {
                  setExpandedSubMenuAnchorEl(null);
                  setSearchQuery("");
                }}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 326, left: 520 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    maxHeight: "200px",
                    width: "270px",
                    overflowY: "auto",
                  },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "200px",
                  }}
                >
                  <Mui.TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <Mui.InputAdornment position="end">
                          <SearchIcon />
                        </Mui.InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ flex: 1, overflowY: "auto", height: "50px" }}>
                    {filteredData?.length > 0 ? (
                      filteredData.map((data) => (
                        <Mui.MenuItem
                          key={data.id}
                          onClick={() => {
                            if (data && data.label) {
                              dispatch(setDepartmentName(data.label));
                              localStorage.setItem(
                                "approveLeaveDepartmentId",
                                data.id ? data.id : 99999
                              );
                              localStorage.setItem(
                                "approveLeaveDepartmentName",
                                data.label
                              );
                              dispatch(
                                setDepartmentId(data.id ? data.id : 99999)
                              );
                              setShiftCatagoryName(
                                data.label === "All"
                                  ? "All"
                                  : data.shift_category_name
                              );
                              fetchShiftCatagoryDetails(
                                data.id ? data.id : 99999,
                                data.label
                              );
                            }
                            setSearchQuery("");
                            setExpandedSubMenuAnchorEl(null);
                            setExpandedSubMenuAnchorEl(null);
                            dispatch(setReloadHRMSApi(true));
                            setPage(0);
                          }}
                        >
                          {data.label}
                        </Mui.MenuItem>
                      ))
                    ) : (
                      <Mui.MenuItem disabled>No data available</Mui.MenuItem>
                    )}
                  </div>
                </div>
              </Mui.Popover>
            </Mui.Grid>
            <Mui.Grid item xs={6} sx={{ marginLeft: 48, paddingTop: 5 }}>
              <Mui.Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 3,
                }}
              >
                <Mui.IconButton
                  aria-label="close"
                  onClick={() =>
                    view === "List" ? setView("Card") : setView("List")
                  }
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {view === "List" ? (
                    <Mui.Tooltip
                      title="Card View"
                      placement="bottom"
                      key="card-view-tooltip"
                    >
                      <CreditCard
                        style={{
                          color: themes.headLine,
                        }}
                      />
                    </Mui.Tooltip>
                  ) : (
                    <Mui.Tooltip
                      title="List View"
                      placement="bottom"
                      key="list-view-tooltip"
                    >
                      <List
                        style={{
                          color: themes.headLine,
                        }}
                      />
                    </Mui.Tooltip>
                  )}
                </Mui.IconButton>
                <Mui.Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  style={{
                    width: 300,
                    marginLeft: 5,
                  }}
                  value={
                    shiftData?.find((option) => option?.label === shiftName) ||
                    shiftName ||
                    ""
                  }
                  options={shiftData}
                  getOptionLabel={(option) =>
                    option?.label ? option?.label : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.label === value?.label
                  }
                  filterOptions={(options, state) => {
                    return options?.filter((option) =>
                      option?.label
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                  renderOption={(props, option) => {
                    return <li {...props}>{option?.label}</li>;
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "150px",
                    },
                  }}
                  onChange={async (event, value) => {
                    setshiftId("");
                    setShiftName("");
                    if (value && value?.label) {
                      setShiftName(value.label);
                      setshiftId(value.id);
                      dispatch(setReloadHRMSApi(true));
                      setPage(0);
                    }
                  }}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      sx={{ background: themes.whiteColor }}
                      placeholder="Select shift"
                      label="Select Shift"
                    />
                  )}
                />
                <Mui.TextField
                  type="date"
                  size="small"
                  label="Select Date"
                  value={date}
                  style={{
                    width: 300,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                  onChange={(event) => {
                    setFilterDate(
                      moment(new Date(event.target.value)).format("YYYY-MM-DD")
                    );
                    dispatch(setReloadHRMSApi(true));
                    setPage(0);
                  }}
                  fullWidth
                />

                <Mui.IconButton onClick={() => summarizeExport()}>
                  <DownloadForOfflineIcon
                    size="large"
                    sx={{ color: "#25B7D3", fontSize: 50 }}
                  />
                </Mui.IconButton>

                {/* <CustomButton
              actionFuntion={
                !triggerSubmit
                  ? null
                  : () => {
                      dispatch(setReloadHRMSApi(true));
                    }
              }
              actionName="Filter"
              typeName="button"
            /> */}
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <StyledGrid container sx={{ paddingTop: 4 }}>
            <ContentGrid
              item
              xs={12}
              sx={{
                paddingTop: {
                  xs: 5,
                  md: 7,
                  sm: 8,
                  lg: 0,
                },
              }}
            >
              <Mui.Grid>
                {deviceOffine ? (
                  ""
                ) : (
                  <Mui.Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Mui.Card
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 20,
                        width: "100%",
                        justifyContent: "space-evenly",
                        marginLeft: 20,
                        marginRight: 20,
                        borderColor: themes.primary,
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {status?.map((value) => (
                        <>
                          <Mui.Card
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "50%",
                              height: 50,
                              fontSize: 20,
                              fontWeight: "bold",
                              borderRadius: 0,
                              color: themes.headLine,
                              backgroundColor:
                                selectedValue === value?.name
                                  ? themes.primary
                                  : "white",
                            }}
                            onClick={() => {
                              setExpand(true);
                              setSelectedValue(value?.name);
                              setPage(0);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {value?.name} {value?.count}
                          </Mui.Card>
                        </>
                      ))}
                    </Mui.Card>
                  </Mui.Grid>
                )}
              </Mui.Grid>

              {loading ? (
                <Loader info="Loading..." />
              ) : view === "List" ? (
                <ListView
                  departmentMembers={departmentMembers}
                  filterDepartMemberList={filterDepartMemberList}
                  expand={expand}
                  setExpand={setExpand}
                  setReloadHRMSApi={setReloadHRMSApi}
                  page={page}
                  setPage={setPage}
                />
              ) : (
                <CardView
                  departmentMembers={departmentMembers}
                  setDepartmentMembers={setDepartmentMembers}
                  filterDepartMemberList={filterDepartMemberList}
                  expand={expand}
                  setExpand={setExpand}
                  page={page}
                  setPage={setPage}
                />
              )}
            </ContentGrid>
          </StyledGrid>
        </>
      )}
      {deviceOffine ? (
        <Mui.Grid sx={{ paddingTop: 20 }}>
          <Device />
        </Mui.Grid>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const StyledGrid = styled(Mui.Grid)`
  height: 20vh;
  // overflow: hidden; /* Hide the scrollbar of the main container */
`;

const ContentGrid = styled(Mui.Grid)`
  width: 80%;
`;
