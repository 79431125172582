import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { DownloadForOffline } from "@mui/icons-material";
import { BackArrow } from "components/BackArrow";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "components/Status";
import { ESIReportTable } from "./ESIReportTable";

export const ESIReport = ({ setShowReport }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [address, setCompanyAddress] = useState("");
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [updateCompanyDetails, setUpdateCompanyDetails] = useState(true);
  
  useEffect(() => {
    if (companyList.length !== 0 && updateCompanyDetails) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
      setCompanyAddress(data[0].address);
      setUpdateCompanyDetails(false);
    }
  }, [companyList, updateCompanyDetails]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
    }
  };

  useEffect(() => {
    const getSalaryStatementReport = async () => {
      try {
        const response = await fetch(
          `${domain}payrun/report/?pay_schedule_id=44`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setSalaryDetails(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    getSalaryStatementReport();
  }, [domain, token]);

  const DownloadData = () => {};

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 1 }}>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 2 }}>
          <BackArrow actionFunction={() => setShowReport(false)} />
        </Mui.Grid>
        <Mui.Grid xs={2.5}>
          <Mui.Typography>Company Name</Mui.Typography>
          <CustomAutoComplete
            dataList={companyList.map((obj) => ({
              ...obj,
              label: obj.companyname,
            }))}
            selectedValue={companyName}
            updateDataFunction={handleCompanyName}
            placeHolder="Please select the company name"
          />
        </Mui.Grid>
        <Mui.Grid xs={8.5}></Mui.Grid>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 1 }}>
          <Mui.IconButton onClick={() => DownloadData()}>
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 50 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          justifyContent: "center",
          backgroundColor: themes.formBackground,
        }}
      >
        <Mui.Stack>
          <Mui.Typography
            sx={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}
          >
            {companyName}
          </Mui.Typography>
          <Mui.Typography sx={{ fontSize: 20, textAlign: "center" }}>
            {address}
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid container sx={{ justifyContent: "center", paddingTop: 2 }}>
        <Mui.Typography
          sx={{ fontSize: 25, textAlign: "center", fontWeight: "bold" }}
        >
          Salary Register For The Period {} to {}
        </Mui.Typography>
      </Mui.Grid>
      <ESIReportTable salaryDetails={salaryDetails} />
    </React.Fragment>
  );
};
