import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "components/Status";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import { employee_use } from "services/constants";
import {
  aadharRegex,
  onlyNumberRegex,
  panRegex,
  mustbe12Digits,
  pfNumberRegex,
  specialCharacterRestrict,
} from "services/constants/patterns";
import { CustomTextBox } from "components/CustomTextBox";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomButton";

export const PersonalDetailsForm = ({
  setPageNo,
  existingUserDetails,
  setUpdateBasicDetailsData,
  setExistingUserDetails,
  openDialogBox,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [aadharNo, setAadharNo] = useState(
    existingUserDetails?.aadhar_number ? existingUserDetails?.aadhar_number : ""
  );
  const [panNo, setPanNo] = useState(
    existingUserDetails?.pan_number ? existingUserDetails?.pan_number : ""
  );
  const [pfNo, setPFNo] = useState(
    existingUserDetails?.pf_number ? existingUserDetails?.pf_number : ""
  );
  const [esiNo, setESINo] = useState(
    existingUserDetails?.esi_number ? existingUserDetails?.esi_number : ""
  );
  const [uanNo, setUANNo] = useState(
    existingUserDetails?.uan_number ? existingUserDetails?.uan_number : ""
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    existingUserDetails?.date_of_birth
      ? existingUserDetails?.date_of_birth?.split("-").reverse().join("-")
      : null
  );
  const [addressDetails, setAddressDetails] = useState(
    existingUserDetails?.address ? existingUserDetails?.address : ""
  );
  const [permanentAddressDetails, setPermanentAddressDetails] = useState(
    existingUserDetails?.permanent_address
      ? existingUserDetails?.permanent_address
      : ""
  );
  const [employeeUse, setEmployeeUse] = useState(
    existingUserDetails?.transport_type
      ? existingUserDetails?.transport_type
      : "Company accommodation"
  );
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [blockNo, setBlockNo] = useState(
    existingUserDetails?.block_name ? existingUserDetails?.block_name : ""
  );
  const [roomNo, setRoomNo] = useState(
    existingUserDetails?.room_no ? existingUserDetails?.room_no : ""
  );
  const [transportRouteNumber, setTransportRouteNumber] = useState(
    existingUserDetails?.transport_route_no
      ? existingUserDetails?.transport_route_no
      : ""
  );

  const [communicationAddressCheckBox, setCommunicationAddressCheckBox] =
    useState(
      existingUserDetails?.permanent_address === existingUserDetails?.address &&
        existingUserDetails?.permanent_address
        ? true
        : false
    );

  useEffect(() => {
    setValue(
      "aadhar",
      existingUserDetails?.aadhar_number
        ? existingUserDetails?.aadhar_number
        : ""
    );
    setValue(
      "panNumber",
      existingUserDetails?.pan_number ? existingUserDetails?.pan_number : ""
    );
    setValue(
      "pfNumber",
      existingUserDetails?.pf_number ? existingUserDetails?.pf_number : ""
    );
    setValue(
      "dateofbith",
      existingUserDetails?.date_of_birth
        ? existingUserDetails?.date_of_birth?.split("-").reverse().join("-")
        : null
    );
    setValue(
      "address",
      existingUserDetails?.address ? existingUserDetails?.address : ""
    );
    setValue(
      "uanNumber",
      existingUserDetails?.uan_number ? existingUserDetails?.uan_number : ""
    );
    setValue(
      "esiNumber",
      existingUserDetails?.esi_number ? existingUserDetails?.esi_number : ""
    );
    setValue(
      "permanent_address",
      existingUserDetails?.permanent_address
        ? existingUserDetails?.permanent_address
        : ""
    );
    setValue(
      "room_no",
      existingUserDetails?.room_no ? existingUserDetails?.room_no : ""
    );
    setValue(
      "transport_ro_number",
      existingUserDetails?.transport_route_no
        ? existingUserDetails?.transport_route_no
        : ""
    );
    setValue(
      "transport",
      existingUserDetails?.transport_type
        ? existingUserDetails?.transport_type
        : "Company accommodation"
    );
    setValue(
      "block_name",
      existingUserDetails?.block_name ? existingUserDetails?.block_name : ""
    );
  }, [
    existingUserDetails?.aadhar_number,
    existingUserDetails?.address,
    existingUserDetails?.block_name,
    existingUserDetails?.date_of_birth,
    existingUserDetails?.esi_number,
    existingUserDetails?.pan_number,
    existingUserDetails?.permanent_address,
    existingUserDetails?.pf_number,
    existingUserDetails?.room_no,
    existingUserDetails?.transport_route_no,
    existingUserDetails?.transport_type,
    existingUserDetails?.uan_number,
    setValue,
  ]);

  const onSubmit = async (data) => {
    setExistingUserDetails({
      ...existingUserDetails,
      aadhar_number: data.aadhar,
      pan_number: data.panNumber,
      pf_number: data.pfNumber,
      esi_number: data.esiNumber,
      uan_number: data.uanNumber,
      address: data.address,
      permanent_address: data.permanent_address,
      date_of_birth: data.dateofbith?.split("-").reverse().join("-"),
      transport_route_no: data?.transport_ro_number,
      transport_type: data?.transport,
      block_name: data?.block_name,
      room_no: data?.room_no,
    });
    if (saveAndExit) {
      saveDataAndExists(data);
      setSaveAndExit(false);
    } else {
      setPageNo(3);
      reset();
    }
  };

  const saveDataAndExists = async (data) => {
    try {
      const response = await fetch(`${domain}employee-onboard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_name: existingUserDetails.user_name,
          email: existingUserDetails.email_id,
          company_id: existingUserDetails.company_id,
          portal_access: existingUserDetails.portal_access,
          employee_id: existingUserDetails.employee_id,
          date_joined: existingUserDetails.date_of_joined
            ? existingUserDetails.date_of_joined?.split("-").reverse().join("-")
            : null,
          leave_group_id: existingUserDetails?.leave_group_id,
          department_id: existingUserDetails.department_id,
          designation_id: existingUserDetails.designation_id,
          employment_type_id: existingUserDetails.employment_type_id,
          parent_name: existingUserDetails.parent_name,
          parent_mobile_number: existingUserDetails.parent_mobile_number,
          bankname: existingUserDetails.bank_name,
          account_holder_name: existingUserDetails.account_holder_name,
          accountno: existingUserDetails.account_number,
          ifsc: existingUserDetails.ifsc,
          branch_name: existingUserDetails.branch_name,
          pan: data.panNumber,
          aadhar: data.aadhar,
          address: data.address,
          pf: data.pfNumber,
          uan: data.uanNumber,
          esi: data.esiNumber,
          dob: data.dateofbith,
          emergency_contacts: existingUserDetails.emergency_contacts,
          permanent_address: data?.permanent_address,
          transport_route_no: data?.transport_ro_number,
          transport_type: data?.transport,
          block_name: data?.block_name,
          room_no: data?.room_no,
          phone_number: existingUserDetails.phone_number,
          salary_group: existingUserDetails.salary_group,
          earnings: existingUserDetails.earnings,
          deductions: existingUserDetails.deductions,
          salary_type: existingUserDetails.salary_type,
          amount: existingUserDetails.amount,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        // setOpenDialog(true);
        openDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateAadharCode = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setValue("aadhar", event.target.value);
      setAadharNo(event.target.value);
    }
  };

  const updatePanNumber = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 10
    ) {
      setValue("panNumber", event.target.value);
      setPanNo(event.target.value);
    }
  };

  const updatePFNumber = (event) => {
    if (event.target.value.length <= 22) {
      setValue("pfNumber", event.target.value);
      setPFNo(event.target.value);
    }
  };

  const handleDateOfBirth = (event) => {
    setValue("dateofbith", event.target.value);
    setDateOfBirth(event.target.value);
  };

  const updateAddress = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("address", event.target.value);
      setAddressDetails(event.target.value);
      if (communicationAddressCheckBox) {
        setValue("permanent_address", event.target.value);
        setPermanentAddressDetails(event.target.value);
      }
    }
  };

  const updatePermanentAddress = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("permanent_address", event.target.value);
      setPermanentAddressDetails(event.target.value);
    }
  };

  const updateUANNumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setUANNo(event.target.value);
      setValue("uanNumber", event.target.value);
    }
  };

  const updateESINumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 17
    ) {
      setValue("esiNumber", event.target.value);
      setESINo(event.target.value);
    }
  };

  const handleEmployeeUse = (event, data) => {
    if (data) {
      setBlockNo("");
      setTransportRouteNumber("");
      setRoomNo("");
      setValue("transport", data.label);
      setEmployeeUse(data.label);
    }
  };

  const updateBlockNumber = (event) => {
    if (event.target.value.length < 32) {
      setValue("block_name", event.target.value);
      setBlockNo(event.target.value);
    }
  };

  const updateRoomNumber = (event) => {
    if (event.target.value.length <= 10) {
      setValue("room_no", event.target.value);
      setRoomNo(event.target.value);
    }
  };

  const updateTransportRouteNumber = (event) => {
    if (event.target.value.length < 32) {
      setValue("transport_ro_number", event.target.value);
      setTransportRouteNumber(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Aadhar number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={aadharNo}
              rules={{
                ...register("aadhar", {
                  required: false,
                  pattern: {
                    pattern1: (value) =>
                      aadharRegex.test(value) ||
                      "Enter aadhar in format 123456789012",
                    pattern2: (value) =>
                      mustbe12Digits.test(value) ||
                      "Mimimum 12 digits required",
                  },
                  maxLength: {
                    value: 12,
                    message: "Mimimum 12 digits required",
                  },
                  minLength: {
                    value: 12,
                    message: "Mimimum 12 digits required",
                  },
                }),
              }}
              updateDataFunction={updateAadharCode}
              placeHolder="Please enter the aadhar number"
              errorMessage={
                errors?.aadhar?.message ? errors?.aadhar?.message : ""
              }
              textBoxError={errors.aadhar ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Pan number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={panNo}
              rules={{
                ...register("panNumber", {
                  required: false,
                  pattern: {
                    value: panRegex,
                    message: "Enter PAN in format AAAAA9999A",
                  },
                  minLength: {
                    value: 10,
                    message: "Mimimum 12 digits characters required",
                  },
                }),
              }}
              updateDataFunction={updatePanNumber}
              placeHolder="Please enter the PAN number"
              errorMessage={
                errors?.panNumber?.message ? errors?.panNumber?.message : ""
              }
              textBoxError={errors.panNumber ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              PF number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={pfNo}
              rules={{
                ...register("pfNumber", {
                  required: false,
                  pattern: {
                    value: pfNumberRegex,
                    message: "Enter PF in format MHBAN00000160000000134",
                  },
                  minLength: {
                    value: 22,
                    message: "Mimimum 22 characters required",
                  },
                }),
              }}
              updateDataFunction={updatePFNumber}
              placeHolder="Please enter the PF number"
              errorMessage={
                errors?.pfNumber?.message ? errors?.pfNumber?.message : ""
              }
              textBoxError={errors.pfNumber ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              UAN number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={uanNo}
              rules={{
                ...register("uanNumber", {
                  required: false,
                  pattern: {
                    value: mustbe12Digits,
                    message: "Enter UAN in format 123456789012",
                  },
                  minLength: {
                    value: 12,
                    message: "Mimimum 12 digits required",
                  },
                }),
              }}
              updateDataFunction={updateUANNumber}
              placeHolder="Please enter the UAN number"
              errorMessage={
                errors?.uanNumber?.message ? errors?.uanNumber?.message : ""
              }
              textBoxError={errors.uanNumber ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              ESI number
            </Mui.Typography>
            <CustomTextBox
              selectedValue={esiNo}
              rules={{
                ...register("esiNumber", {
                  required: false,
                  minLength: {
                    value: 17,
                    message: "Mimimum 17 digits required",
                  },
                }),
              }}
              updateDataFunction={updateESINumber}
              placeHolder="Please enter the ESI number"
              errorMessage={
                errors?.esiNumber?.message ? errors?.esiNumber?.message : ""
              }
              textBoxError={errors.esiNumber ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Date of Birth
            </Mui.Typography>
            <Mui.TextField
              type="date"
              value={dateOfBirth}
              inputProps={{
                max: new Date().toISOString().split("T")[0], // Set the max date to today
              }}
              rules={{
                ...register("dateofbith", {
                  required: false,
                }),
              }}
              size="small"
              onChange={(event) => {
                handleDateOfBirth(event);
              }}
              helperText={
                errors?.dateofbith?.message ? errors?.dateofbith?.message : ""
              }
              error={errors.dateofbith ? true : false}
              fullWidth
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Communication address
          </Mui.Typography>
          <CustomTextBox
            type="multiline"
            selectedValue={addressDetails}
            rules={{
              ...register("address", {
                required: false,
              }),
            }}
            updateDataFunction={updateAddress}
            placeHolder="Enter Address"
            errorMessage={
              errors?.address?.message ? errors?.address?.message : ""
            }
            textBoxError={errors.address ? true : false}
          />
        </Mui.Grid>
        {/* newChanges */}
        <Mui.Grid container>
          <Mui.Checkbox
            checked={communicationAddressCheckBox}
            onChange={(event) => {
              setCommunicationAddressCheckBox(event.target.checked);
              if (event.target.checked) {
                setValue("permanent_address", addressDetails);
                setPermanentAddressDetails(addressDetails);
              } else {
                setValue("permanent_address", "");
                setPermanentAddressDetails("");
              }
            }}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
          />{" "}
          <Mui.Typography
            sx={{ paddingTop: 1.5, fontSize: 18, color: themes.colorShadow }}
          >
            Is communication address same as permanent address
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 15,
              color: themes.colorShadow,
              paddingBottom: 0.5,
            }}
          >
            Permanent address
          </Mui.Typography>
          <CustomTextBox
            type="multiline"
            selectedValue={permanentAddressDetails}
            rules={{
              ...register("permanent_address", {
                required: false,
              }),
            }}
            updateDataFunction={updatePermanentAddress}
            placeHolder="Enter Permanent Address"
            errorMessage={
              errors?.permanent_address?.message
                ? errors?.permanent_address?.message
                : ""
            }
            textBoxError={errors.permanent_address ? true : false}
            disabledValue={communicationAddressCheckBox ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.Grid xs={5}>
            <Mui.Typography>Employee use</Mui.Typography>
            <CustomAutoComplete
              dataList={employee_use}
              selectedValue={employeeUse}
              rules={{
                ...register("transport", {
                  required: false,
                }),
              }}
              updateDataFunction={handleEmployeeUse}
              placeHolder="Select the employee use"
              errorMessage={
                errors?.transport?.message ? errors?.transport?.message : ""
              }
              textBoxError={errors?.transport ? true : false}
            />
          </Mui.Grid>
          {employeeUse === "Company accommodation" ? (
            <>
              <Mui.Grid xs={0.5}></Mui.Grid>
              <Mui.Grid xs={4.1}>
                <Mui.Typography>Block Name</Mui.Typography>
                <CustomTextBox
                  selectedValue={blockNo}
                  rules={{
                    ...register("block_name", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={updateBlockNumber}
                  placeHolder="Enter block name"
                  errorMessage={
                    errors?.block_name?.message
                      ? errors?.block_name?.message
                      : ""
                  }
                  textBoxError={errors.block_name ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid xs={0.3}></Mui.Grid>
              <Mui.Grid xs={2.1}>
                <Mui.Typography>Room No</Mui.Typography>
                <CustomTextBox
                  selectedValue={roomNo}
                  rules={{
                    ...register("room_no", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={updateRoomNumber}
                  placeHolder="Enter room number"
                  errorMessage={
                    errors?.room_no?.message ? errors?.room_no?.message : ""
                  }
                  textBoxError={errors.room_no ? true : false}
                />
              </Mui.Grid>
            </>
          ) : employeeUse === "Company transport" ? (
            <>
              <Mui.Grid xs={0.5}></Mui.Grid>{" "}
              <Mui.Grid xs={6.5}>
                <Mui.Typography>Transport route no</Mui.Typography>
                <CustomTextBox
                  selectedValue={transportRouteNumber}
                  rules={{
                    ...register("transport_ro_number", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={updateTransportRouteNumber}
                  placeHolder="Enter Route number"
                  errorMessage={
                    errors?.transport_ro_number?.message
                      ? errors?.transport_ro_number?.message
                      : ""
                  }
                  textBoxError={errors.transport_ro_number ? true : false}
                />
              </Mui.Grid>
            </>
          ) : null}
        </Mui.Grid>
        {/* newChanges */}
        <Mui.Grid
          container
          sx={{
            paddingTop: {
              xs: 0,
              sm: 0,
              md: 10,
              lg: 10,
            },
          }}
        >
          <Mui.Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "flex-start",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setUpdateBasicDetailsData(true);
                setPageNo(1);
              }}
              actionName="Previous"
              typeName="submit"
            />
          </Mui.Grid>

          <Mui.Grid
            xs={8}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "flex-end",
              paddingRight: 2,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setSaveAndExit(true);
              }}
              actionName="Save & Exit"
              typeName="submit"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionName="Next"
              typeName="submit"
              actionFuntion={() => {
                setSaveAndExit(false);
              }}
            />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
