import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Person, Person4, MoreVert } from "@mui/icons-material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { hrmsEmployeeFliter } from "services/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "components/Status";
import { CustomButton } from "components/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { Loader } from "components/Loader";
import {
  setEditEmployee,
  setEditEmployeeID,
  setEmployeeScreenAction,
} from "services/Redux/hrms";
import { useDispatch } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";

const employeeHeader = [
  "Employee name",
  "Employee id",
  "User Code",
  "Email id",
  "Status",
  "Actions",
];
export const Employee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [openDeactivatePopup, setOpenDeactivatePopup] = useState(false);
  const [openActivatePopup, setOpenActivatePopup] = useState(false);
  const { result: designation } = useFetch(`${domain}designation/`);
  const { result: employmentType } = useFetch(`${domain}employment-type/`);
  const [masterEmployee, setMasterEmployee] = useState([]);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${domain}list/employee`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setLoading(false);
          setEmployeeDetails(res);
          setMasterEmployee(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchEmployeeDetails();
  }, [domain, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showCardActions = () => {
    if (employeeDetails?.length === 0) {
      if (designation.length !== 0 && employmentType.length !== 0) {
        setShowCardDetails(true);
      } else {
        setOpenDialogBox(true);
      }
    } else {
      setShowCardDetails(true);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const editDetailsConfigurations = () => {
    dispatch(setEmployeeScreenAction("Edit Employee"));
    localStorage.setItem("editEmployeeIdNumber", editDetails.user_id);
    localStorage.setItem("editEmployeeDetails", true);
    dispatch(setEditEmployeeID(editDetails.user_id));
    dispatch(setEditEmployee(true));
    setAnchorEl(null);
    history.push("/admin/teamzone/employee/onborad");
    dispatch(setGlobalSearchValue(""));
  };

  const deactivateEmployee = async () => {
    try {
      const response = await fetch(`${domain}deactivate/`, {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: editDetails.user_id,
          status: "Inactive",
        }),
      });
      const res = await response.json();
      if (response.status === 201 && response.ok) {
        setAnchorEl(null);
        showToast("error", "User inactivated successfully");
        const field = "status";
        const updatedUsers = employeeDetails.map((element) => {
          if (element.user_id === editDetails.user_id) {
            return { ...element, [field]: "Inactive" };
          }
          return element;
        });
        setEmployeeDetails(updatedUsers);
        handleDeactivateDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      handleDeactivateDialogBox();
    }
  };

  const activateEmployee = async () => {
    try {
      const response = await fetch(`${domain}deactivate/`, {
        method: "Put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: editDetails.user_id,
          status: "Active",
        }),
      });
      const res = await response.json();
      if (response.status === 201 && response.ok) {
        setAnchorEl(null);
        showToast("error", "User activated successfully");
        const field = "status";
        const updatedUsers = employeeDetails.map((element) => {
          if (element.user_id === editDetails.user_id) {
            return { ...element, [field]: "Active" };
          }
          return element;
        });
        setEmployeeDetails(updatedUsers);
        handleActivateDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      handleActivateDialogBox();
    }
  };

  const handleDeactivateDialogBox = () => {
    setOpenDeactivatePopup(false);
    setAnchorEl(null);
  };

  const handleActivateDialogBox = () => {
    setOpenActivatePopup(false);
    setAnchorEl(null);
  };

  const handleCloseInfoDialog = () => {
    setOpenDialogBox(false);
  };

  useEffect(() => {
    const filteredResults = masterEmployee.filter((item) =>
      hrmsEmployeeFliter.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setEmployeeDetails(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterEmployee]);

  return loading ? (
    <Loader info="Loading..." />
  ) : !showCardDetails ? (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: 1,
          paddingTop: 3,
        }}
      >
        <CustomButton
          actionFuntion={showCardActions}
          actionName="Add Employee"
          typeName="button"
        />
      </Mui.Grid>
      {employeeDetails.length !== 0 ? (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: 840 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                {employeeHeader.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {employeeDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow key={data.id}>
                    <>
                      <Mui.TableCell align="left">
                        <Mui.Stack direction="Row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt="Remy Sharp"
                            src={data.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {data.user_name ? data.user_name : "N/A"}
                          </Mui.Typography>
                        </Mui.Stack>
                      
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.employee_id ? data?.employee_id : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.user_code ? data?.user_code : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.email ? data?.email : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.status ? data?.status : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <Mui.IconButton
                          onClick={(e) => {
                            setEditDetails(data);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVert
                            sx={{ color: themes.blackColor, strokeWidth: 2 }}
                          />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                            onClick={() => editDetailsConfigurations()}
                          >
                            Edit
                          </Mui.MenuItem>
                          {editDetails?.status === "Active" ? (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenDeactivatePopup(true);
                              }}
                            >
                              Inactive
                            </Mui.MenuItem>
                          ) : editDetails?.status === "Expired" ||
                            editDetails?.status === "Invited" ? null : (
                            <Mui.MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setOpenActivatePopup(true);
                              }}
                            >
                              Activate
                            </Mui.MenuItem>
                          )}
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {employeeDetails.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={employeeDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : loading || employeeDetails.length !== 0 ? null : (
        <Mui.Alert severity="info">There is a no available employees</Mui.Alert>
      )}
      <GlobalDialogBox
        open={openDialogBox}
        handleCloseDialog={handleCloseInfoDialog}
        title="Warning"
      >
        <Mui.Typography>
          `To onboard an employee, please create a designation and leave the
          group before proceeding. Designations can be created by going to
          Administration {">"} Masters {">"} Add Designation. Leave group can be
          created by going to HRMS {">"}Leave Group {">"}Add Leave Group`
        </Mui.Typography>
        <Mui.Grid container sx={{ justifyContent: "flex-end", paddingTop: 1 }}>
          <Mui.Button
            variant="contained"
            size="small"
            sx={{
              background: themes.primaryIndicator,
              color: themes.headLine,
              padding: 1,
            }}
            onClick={() => handleCloseInfoDialog()}
          >
            Ok
          </Mui.Button>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={openDeactivatePopup}
        handleCloseDialog={handleDeactivateDialogBox}
        title={"Inactivate the user"}
      >
        <Mui.Typography sx={{ fontSize: 22 }}>
          Do you want to inactivate the user ?
        </Mui.Typography>
        <Mui.Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-end", paddingTop: 1 }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => handleDeactivateDialogBox()}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => deactivateEmployee()}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        open={openActivatePopup}
        handleCloseDialog={handleActivateDialogBox}
        title={"Activate the user"}
      >
        <Mui.Typography sx={{ fontSize: 22 }}>
          Do you want to activate the user ?
        </Mui.Typography>
        <Mui.Grid
          container
          spacing={2}
          sx={{ justifyContent: "flex-end", paddingTop: 1 }}
        >
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => handleActivateDialogBox()}
              actionName="No"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid item>
            <CustomButton
              actionFuntion={() => activateEmployee()}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  ) : (
    <Mui.Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        padding: "0 16px",
        "@media (max-width:600px)": {
          minHeight: "50vh",
        },
      }}
    >
      <Mui.Stack direction="Row">
        <Mui.Card
          spacing={2}
          className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg"
          variant="outlined"
          sx={{
            width: "15vw",
            transitionDuration: "0.3s",
            height: "10vw",
            border: `1px solid ${themes.shadowColor}`,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setEmployeeScreenAction("Existing User"));
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/teamzone/employee/onborad");
          }}
        >
          <Mui.CardContent
            sx={{
              justifyContent: "center",
              alignContent: "center",
              minHeight: "20vh",
            }}
          >
            <Mui.Typography sx={{ textAlign: "center" }}>
              <Person4 />
              {"  "}Existing User
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card>
        &nbsp;&nbsp;&nbsp;
        <Mui.Card
          spacing={2}
          className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg"
          variant="outlined"
          sx={{
            width: "15vw",
            transitionDuration: "0.3s",
            height: "10vw",
            paddingLeft: 2,
            border: `1px solid ${themes.shadowColor}`,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/teamzone/new/employee");
          }}
        >
          <Mui.CardContent
            sx={{
              justifyContent: "center",
              alignContent: "center",
              minHeight: "20vh",
            }}
          >
            <Mui.Typography sx={{ textAlign: "center" }}>
              <Person />
              {"  "}New Employee
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card>
        {/* &nbsp;&nbsp;&nbsp;
        <Mui.Card
          spacing={2}
          className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg"
          variant="outlined"
          sx={{
            width: "15vw",
            transitionDuration: "0.3s",
            height: "10vw",
            paddingLeft: 2,
            border: `1px solid ${themes.shadowColor}`,
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setGlobalSearchValue(""));
            history.push("/admin/teamzone/bulk/import");
          }}
        >
          <Mui.CardContent
            sx={{
              justifyContent: "center",
              alignContent: "center",
              minHeight: "20vh",
            }}
          >
            <Mui.Typography sx={{ textAlign: "center" }}>
              <Person />
              Bulk Import
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card> */}
      </Mui.Stack>
    </Mui.Grid>
  );
};
