import React, { useState, useEffect } from "react";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { HrmsLeave } from "./HrmsLeave";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Grid,
  Alert,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Cancel, Done, Edit } from "@material-ui/icons";
import { CustomButton } from "components/CustomButton";
import * as Mui from "@mui/material";
import { themes, defaultAllSelection } from "services/constants";
import { Loader } from "components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { CalendarMonth } from "@mui/icons-material";
import { leaveFilterKey } from "services/constants";
import { PastLeaveDetails } from "./PastLeaveDetails";
import TablePagination from "@mui/material/TablePagination";
import {
  Visibility as VisibilityIcon,
  AccountBalance,
  KeyboardArrowDown,
} from "@mui/icons-material";
import "./ButtonGroup.css";
import { styled } from "@mui/system";
import { showToast } from "components/Status";
import "react-toastify/dist/ReactToastify.css";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { useSelector, useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { parse, format } from "date-fns";
import sessionHandling from "services/utils/notificationUtils";

const HoverVisibilityIconButton = styled(IconButton)`
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
export const LeaveSummary = () => {
  const dispatch = useDispatch();
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [departmentData, setDepartmentData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reUpdatePastLeaveDetails, setReUpdatePastLeaveDetails] =
    useState(false);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [leaveDate, setLeaveDate] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [applyLeave, setApplyLeave] = useState(false);
  const [expandedSubMenuAnchorEl, setExpandedSubMenuAnchorEl] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [newTotalLeave, setNewTotalLeave] = useState("");
  const [shiftId, setshiftId] = useState("99999");
  const [shiftName, setShiftName] = useState("All");
  const [shiftData, setShiftData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [userId, setUserId] = useState(userDetails?.user_id);
  const [selUsername, setSelUsername] = useState("All");
  const [allUsers, setAllUsers] = useState([]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = departmentData.filter((data) =>
    data.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await fetch(
          `${domain}list/employee?user_id=${userDetails?.id}&department_id=${departmentId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let userDetails = res.map((obj) => ({
            ...obj,
            label: obj.user_name,
          }));
          const allUsername = {
            label: "All",
            user_id: defaultAllSelection,
            user_name: "All",
          };
          setAllUsers([allUsername, ...userDetails]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    fetchAllUsers();
  }, [userDetails?.id, domain, token, departmentId]);

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const handleSave = async (userId, newTotalLeave) => {
    const totalLeave = parseFloat(newTotalLeave);
    try {
      const response = await fetch(`${domain}update-leave-count/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          leave_type_id: shiftId,
          user_id: userId,
          total_leave: totalLeave,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setUpdateData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const departmentDataWithLabel = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          const allDepartment = {
            id: defaultAllSelection,
            label: "All",
            departmentname: "All",
          };
          setDepartmentData([allDepartment, ...departmentDataWithLabel]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, token]);

  useEffect(() => {
    const fetchData = async () => {
      switch (userDetails.role) {
        case "Admin":
        case "Team Lead":
          try {
            setLoading(true);
            const checkUserId = selUsername === "All" ? 99999 : userId;

            const response = await fetch(
              `${domain}leavesummaryadmin/v1/?start_date=${startDate}&end_date=${endDate}&department_id=${departmentId}&leave_type_id=${shiftId}&user_id=${userDetails?.id}&selected_user_id=${checkUserId}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const res = await response.json();
            if (response.ok) {
              setData(res.leave_count);
              setMasterDataForSearch(res.leave_count);
            } else if (response.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res.error);
            }
          } catch (error) {
            showToast("error", error.message);
          } finally {
            setLoading(false);
          }
          break;
        default:
          showToast("error", leaveManagementError.useroleEmptyError);
      }
    };
    if (updateData && startDate && endDate) {
      fetchData();
      setUpdateData(false);
    }
  }, [
    departmentId,
    domain,
    endDate,
    shiftId,
    startDate,
    token,
    updateData,
    userDetails.id,
    userDetails.role,
    selUsername,
    userId,
  ]);

  useEffect(() => {
    const fetchShiftCatagoryDetails = async () => {
      try {
        const response = await fetch(
          `${domain}get-leave-type-based-department/?department_id=${departmentId}&user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let LeaveTypeDetails = res.map((obj) => ({
            ...obj,
            label: obj.leave_type,
          }));
          const allLeaveType = {
            leave_type_id: defaultAllSelection,
            label: "All",
            leave_type: "All",
          };
          setshiftId("99999");
          setShiftName("All");
          setUserId(userDetails?.user_id);
          setSelUsername("All");
          setShiftData([allLeaveType, ...LeaveTypeDetails]);
          setUpdateData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error?.message);
      }
    };
    fetchShiftCatagoryDetails();
  }, [departmentId, domain, token, userDetails?.user_id, userDetails.id]);

  const handleVisibilityToggle = (user) => {
    setSelectedUser(user);
    setShowDetails(true);
  };

  const handleClose = () => {
    setShowDetails(false);
    setApplyLeave(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      leaveFilterKey?.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setData(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  const handleCancel = () => {
    setEditRowId(null);
  };

  const handleKeyPressOnlyNumber = (e) => {
    const charCode = e.charCode;
    const char = String.fromCharCode(charCode);
    if (!(char >= "0" && char <= "9") && char !== ".") {
      e.preventDefault();
    }
  };

  const getTeammateDetails = async (p_startDate, p_endDate, Id) => {
    setLoading(true);
    try {
      const checkUserId = selUsername === "All" ? 99999 : userId;

      const response = await fetch(
        `${domain}leavesummaryadmin/v1/?start_date=${p_startDate}&end_date=${p_endDate}&department_id=${Id}&leave_type_id=${shiftId}&user_id=${userDetails?.id}&selected_user_id=${checkUserId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setData([]);
        setData(res.leave_count);
        setShowDetails(false);
        setReUpdatePastLeaveDetails(false);
        setLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {showDetails === true && !applyLeave ? (
        <Grid container>
          <Grid xs={12}>
            <PastLeaveDetails
              selectedUser={selectedUser}
              setReUpdatePastLeaveDetails={setReUpdatePastLeaveDetails}
              reUpdatePastLeaveDetails={reUpdatePastLeaveDetails}
              filteredValue={filteredValue}
              filteredLabel={filteredLabel}
              setFilteredValue={setFilteredValue}
              setFilteredLabel={setFilteredLabel}
              startDate={startDate}
              endDate={endDate}
              leaveDate={leaveDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setSelectedUser={setSelectedUser}
              shiftId={shiftId}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      ) : applyLeave ? (
        <HrmsLeave handleClose={handleClose} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
            minHeight: 900,
            maxHeight: 900,
          }}
          className="w-96 border  rounded "
          elevation={0}
        >
          {showDetails === false ? (
            <>
              {/* <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 2.2,
                  paddingTop: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setApplyLeave(true);
                  }}
                  actionName="Apply Leave"
                  typeName="button"
                />
              </Mui.Grid> */}
              <Mui.Grid container>
                <Mui.Grid item sx={{ paddingLeft: 2 }}>
                  <Mui.Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: 270,
                      height: 120,
                      backgroundColor: "#FFF8E5",
                    }}
                  >
                    <Mui.Grid container sx={{ paddingLeft: 2 }}>
                      <Mui.Grid
                        xs={2}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#FFD83A",
                          borderRadius: "50%",
                        }}
                      >
                        <AccountBalance
                          style={{ color: "white", alignItems: "center" }}
                          fontSize="medium"
                        />
                      </Mui.Grid>
                      &nbsp; &nbsp;
                      <Mui.Grid xs={7}>
                        <Mui.Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "800",
                            textTransform: "capitalize",
                          }}
                        >
                          {departmentName.length > 10 ? (
                            <Mui.Tooltip title={departmentName}>
                              <span>{`${departmentName.substring(
                                0,
                                10
                              )}..`}</span>
                            </Mui.Tooltip>
                          ) : (
                            departmentName
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={2} sx={{ paddingRight: 2 }}>
                        <Mui.IconButton
                          onClick={(e) =>
                            setExpandedSubMenuAnchorEl(e.currentTarget)
                          }
                        >
                          <KeyboardArrowDown />
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Box>
                  <Mui.Popover
                    anchorEl={expandedSubMenuAnchorEl}
                    open={Boolean(expandedSubMenuAnchorEl)}
                    onClose={() => {
                      setExpandedSubMenuAnchorEl(null);
                      setSearchQuery("");
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 355, left: 536 }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: "200px",
                        width: "270px",
                        overflowY: "auto",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "200px",
                      }}
                    >
                      <Mui.TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <Mui.InputAdornment position="end">
                              <SearchIcon />
                            </Mui.InputAdornment>
                          ),
                        }}
                      />
                      <div
                        style={{ flex: 1, overflowY: "auto", height: "50px" }}
                      >
                        {filteredData.length > 0 ? (
                          filteredData.map((data) => (
                            <Mui.MenuItem
                              key={data.department_id}
                              onClick={() => {
                                if (data && data.label) {
                                  dispatch(setDepartmentName(data.label));
                                  dispatch(setDepartmentId(data.id));
                                }
                                setSearchQuery("");
                                setExpandedSubMenuAnchorEl(null);
                                setExpandedSubMenuAnchorEl(null);
                              }}
                            >
                              {data.label}
                            </Mui.MenuItem>
                          ))
                        ) : (
                          <Mui.MenuItem disabled>
                            No data available
                          </Mui.MenuItem>
                        )}
                      </div>
                    </div>
                  </Mui.Popover>
                </Mui.Grid>
                <Mui.Grid
                  xs={9.8}
                  item
                  sx={{ paddingBottom: 5 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    style={{
                      width: 300,
                      marginLeft: 5,
                    }}
                    value={
                      allUsers?.find(
                        (option) => option?.label === selUsername
                      ) ||
                      selUsername ||
                      ""
                    }
                    options={allUsers}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                      },
                    }}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value && value?.label) {
                        setSelUsername(value.label);
                        setUserId(value.user_id);
                        setUpdateData(true);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="User Name"
                        sx={{ background: "white" }}
                        name="username"
                        placeholder="Select User Name"
                      />
                    )}
                  />
                  &nbsp;&nbsp;
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    style={{
                      width: 300,
                      marginLeft: 5,
                    }}
                    value={
                      shiftData?.find(
                        (option) => option?.label === shiftName
                      ) ||
                      shiftName ||
                      ""
                    }
                    options={shiftData}
                    filterOptions={(options, state) => {
                      return options?.filter((option) =>
                        option?.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option?.label}</li>;
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                      },
                    }}
                    onChange={async (event, value) => {
                      if (value && value?.label) {
                        setShiftName(value.label);
                        setshiftId(value.leave_type_id);
                        setUpdateData(true);
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        sx={{ background: themes.whiteColor }}
                        placeholder="Select Leave Type"
                        label="Leave Type"
                      />
                    )}
                  />
                  &nbsp;&nbsp;
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={
                      leaveDate?.find(
                        (option) => option?.filteredValue === filteredValue
                      ) || ""
                    }
                    options={leaveDate}
                    style={{
                      width: 305,
                    }}
                    getOptionLabel={(option) =>
                      option?.filteredValue ?? filteredValue ?? ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.filteredValue === value?.filteredValue
                    }
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.filteredValue
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.filteredValue}</li>;
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                      },
                    }}
                    onChange={(event, value) => {
                      if (value && value.filteredValue) {
                        setFilteredLabel(value.label);
                        setFilteredValue(value.filteredValue);
                        setStartDate(formatDate(value.start_date));
                        setEndDate(formatDate(value.end_date));
                        getTeammateDetails(
                          formatDate(value.start_date),
                          formatDate(value.end_date),
                          departmentId
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ background: themes.whiteColor }}
                        placeholder="Select leave period"
                        label={filteredLabel ? filteredLabel : "Leave Period"}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <CalendarMonth
                                style={{ margin: "0 8px", color: "#a6a6a6" }}
                              />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </>
          ) : null}
          {data?.length > 0 && !loading ? (
            <Table
              className="border"
              sx={{ borderRadius: "20px", paddingRight: 2, marginTop: 2 }}
            >
              <TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Role</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Planned</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Taken</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Available</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={"bold"}> Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.id}>
                      <TableCell
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {user.user_name}
                      </TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        {editRowId === user.id ? (
                          <TextField
                            sx={{
                              background: themes.whiteColor,
                              width: 120,
                            }}
                            size="small"
                            value={newTotalLeave}
                            onChange={(e) => setNewTotalLeave(e.target.value)}
                            autoFocus
                            onKeyPress={handleKeyPressOnlyNumber}
                          />
                        ) : (
                          user.total_leave
                        )}
                      </TableCell>
                      <TableCell>{user.planned_leave}</TableCell>
                      <TableCell>{user.completed_leave}</TableCell>
                      <TableCell>{user.available_leave}</TableCell>
                      <TableCell>
                        <Mui.Grid container>
                          {editRowId === user.id ? (
                            ""
                          ) : (
                            <Mui.Grid item>
                              <Tooltip title="View" placement="left">
                                <HoverVisibilityIconButton
                                  onClick={() => {
                                    handleVisibilityToggle(user);
                                    setReUpdatePastLeaveDetails(true);
                                    dispatch(setGlobalSearchValue(""));
                                    setEditRowId(null);
                                  }}
                                >
                                  <VisibilityIcon />
                                </HoverVisibilityIconButton>
                              </Tooltip>
                            </Mui.Grid>
                          )}

                          <Mui.Grid item>
                            {editRowId === user.id ? (
                              <>
                                <Mui.IconButton
                                  onClick={() => {
                                    handleSave(user.id, newTotalLeave);
                                    setEditRowId(null);
                                  }}
                                >
                                  <Done
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                                <Mui.IconButton onClick={handleCancel}>
                                  <Cancel
                                    sx={{
                                      color: themes.redColor,
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                              </>
                            ) : parseInt(shiftId) !== 99999 ? (
                              <Tooltip title="Edit" placement="right">
                                <Mui.IconButton
                                  onClick={() => {
                                    setEditRowId(user.id);
                                    setNewTotalLeave(user.total_leave);
                                  }}
                                >
                                  <Edit
                                    sx={{
                                      color: themes.primaryIndicator,
                                      strokeWidth: 2,
                                    }}
                                  />
                                </Mui.IconButton>
                              </Tooltip>
                            ) : null}
                          </Mui.Grid>
                        </Mui.Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : loading ? (
            <div>
              <Loader info="Loading..." />
            </div>
          ) : (
            <Alert sx={{ marginTop: 5 }} severity="info">
              No data available
            </Alert>
          )}
        </TableContainer>
      )}
      {data?.length > 10 && showDetails === false && !applyLeave && (
        <TablePagination
          sx={{ marginRight: 5 }}
          className="custom-pagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};
