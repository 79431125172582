import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomButton";
const payRunKeyPair = [
  {
    name: "Payrun Name",
  },
  {
    name: "Payment Date",
  },
  {
    name: "Pay Run Period",
  },
  {
    name: "Status",
  },
];

export const PayRunTable = ({
  payrunDetails,
  setViewPayRunDetails,
  setViewPayRunScreen,
  setListViewPage,
  listViewPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      {payrunDetails?.length === 0 ? (
        <Mui.Alert severity="info">No data available</Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {payRunKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {payrunDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((payschedule) => (
                  <Mui.TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setViewPayRunDetails(payschedule);
                      setViewPayRunScreen(true);
                    }}
                  >
                    <Mui.TableCell>
                      {payschedule.pay_schedule_name}
                    </Mui.TableCell>
                    <Mui.TableCell>{payschedule.run_date}</Mui.TableCell>
                    <Mui.TableCell>
                      {payschedule.start_date + " to " + payschedule.end_date}
                    </Mui.TableCell>
                    <Mui.TableCell>{payschedule.status}</Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      {payrunDetails?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={payrunDetails?.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
