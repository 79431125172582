import React from "react";
import * as Mui from "@mui/material";
import { UserGroup } from "./UserGroup";
import styled from "styled-components";
import { BackArrow } from "components/BackArrow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

export const ViewCompany = () => {
  const history = useHistory();
  const { companyName } = useSelector((state) => state.userReducer);

  return (
    <React.Fragment>
      <StyledGrid container sx={{ paddingTop: 1 }}>
        <Mui.Grid container>
          <BackArrow actionFunction={() => history.push("/admin/companies")} />
          <Mui.Typography
            sx={{ fontSize: 22, paddingLeft: 1, paddingTop: 1 }}
          >
            {companyName}
          </Mui.Typography>
        </Mui.Grid>
      </StyledGrid>
      <React.Fragment>
        <UserGroup />
      </React.Fragment>
    </React.Fragment>
  );
};
const StyledGrid = styled(Mui.Grid)`
  height: 5vh;
  // overflow: hidden; /* Hide the scrollbar of the main container */
`;