import React, { useState } from "react";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { Earning } from "./Earning";
import { Deduction } from "./Deduction";
import { Expense } from "./Expense";
import { CustomButton } from "components/CustomButton";
import { AddSalaryComponents } from "./AddSalaryComponent";

export const SalaryAndStatutory = ({
  companyId,
  currentScreen,
  companyChange,
  setCompanyChange,
}) => {
  const { domain } = useSelector((state) => state.tokenReducer);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState("Earning");
  const [reloadData, setReloadData] = useState(false);

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Deduction":
        return (
          <Deduction
            domain={domain}
            companyId={companyId}
            reloadData={reloadData}
            selectedComponent={selectedComponent}
            currentScreen={currentScreen}
            setReloadData={setReloadData}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
          />
        );
      case "Earning":
        return (
          <Earning
            domain={domain}
            companyId={companyId}
            reloadData={reloadData}
            selectedComponent={selectedComponent}
            currentScreen={currentScreen}
            setReloadData={setReloadData}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
          />
        );
      case "Expense":
        return (
          <Expense
            domain={domain}
            companyId={companyId}
            reloadData={reloadData}
            selectedComponent={selectedComponent}
            setReloadData={setReloadData}
            currentScreen={currentScreen}
            companyChange={companyChange}
            setCompanyChange={setCompanyChange}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingLeft: 1 }}>
        <Mui.Grid xs={10}>
          <Mui.ButtonGroup size="large" aria-label="Large button group">
            <Mui.Button
              variant={
                selectedComponent === "Earning" ? "contained" : "outlined"
              }
              onClick={() => setSelectedComponent("Earning")}
              sx={{
                background:
                  selectedComponent === "Earning"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  background:
                    selectedComponent === "Earning"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Earning
            </Mui.Button>
            <Mui.Button
              variant={
                selectedComponent === "Deduction" ? "contained" : "outlined"
              }
              onClick={() => setSelectedComponent("Deduction")}
              sx={{
                background:
                  selectedComponent === "Deduction"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  background:
                    selectedComponent === "Deduction"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Deduction
            </Mui.Button>
            <Mui.Button
              variant={
                selectedComponent === "Expense" ? "contained" : "outlined"
              }
              onClick={() => setSelectedComponent("Expense")}
              sx={{
                background:
                  selectedComponent === "Expense"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  background:
                    selectedComponent === "Expense"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Expense
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
        <Mui.Grid
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          <CustomButton
            actionFuntion={() => setOpenDialog(true)}
            actionName="Add salary components"
            typeName="submit"
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>{renderComponent()}</Mui.Grid>
      <AddSalaryComponents
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        companyId={companyId}
        setReloadData={setReloadData}
      />
    </React.Fragment>
  );
};
