export const userHeader = [
  { name: "Name" },
  { name: "User Code" },
  { name: "Designation" },
  { name: "Employment Type" },
  { name: "Department" },
  { name: "Department Lead" },
  { name: "Company Name" },
  { name: "System Access" },
  { name: "Status" },
  { name: "Action" },
];

export const shiftCatagoryHeader = [
  { name: "" },
  { name: "Shift Category Name" },
  { name: "Number of Shifts" },
  { name: "Action" },
];

export const shiftHeader = [
  { name: "Shift Name" },
  { name: "Start Time" },
  { name: "Min Start Time" },
  { name: "Max Start Time" },
  { name: "End Time" },
  { name: "Min End Time" },
  { name: "Max End Time" },
];

export const designationHeader = [
  { name: "" },
  { name: "Designation" },
  { name: "Description" },
  { name: "Action" },
];

export const employmentTypeHeader = [
  { name: "" },
  { name: "Employment Type" },
  { name: "Description" },
  { name: "Action" },
];

export const holidayHeader = [
  { name: "" },
  { name: "Date" },
  { name: "Holiday Name" },
  { name: "Action" },
];

export const departmentHeader = [
  { name: "" },
  { name: "Department Name" },
  { name: "Manager" },
  { name: "Proxy" },
  { name: "Department Members" },
  { name: "Action" },
];

export const departmentMembersHeader = [
  { name: "" },
  { name: "Name" },
  { name: "User code" },
  { name: "Designation" },
  { name: "Employment Type" },
  { name: "Phone Number" },
  { name: "System Access" },
  { name: "Status" },
];
export const departmentMembersHeaderAction = [
  { name: "" },
  { name: "Name" },
  { name: "User code" },
  { name: "Designation" },
  { name: "Employment Type" },
  { name: "Phone Number" },
  { name: "System Access" },
  { name: "Status" },
  { name: "Action" },
];

export const companyHeader = [
  { name: "" },
  { name: "Company Name" },
  { name: "Company Code" },
  { name: "Address" },
  { name: "Gst No" },
  { name: "Email" },
  { name: "Phone Number" },
  { name: "Status" },
  { name: "Type" },
  { name: "Action" },
];

export const userGroupHeader = [
  { name: "" },
  { name: "Group Name" },
  { name: "Users" },
  { name: "Action" },
];

export const groupProductHeader = [
  { name: "" },
  { name: "Products" },
  { name: "Role" },
  { name: "Action" },
];

export const groupUserHeader = [
  { name: "" },
  { name: "Name" },
  { name: "User Code" },
  { name: "Email" },
  { name: "Action" },
];
