import React, { useState } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { Grid, Paper } from "@mui/material";
import { Designation } from "./Screens/Designation";
import { EmploymentType } from "./Screens/EmploymentType";
import { useLocation } from "react-router-dom";
export const Master = () => {
  const [currentScreen, setCurrentScreen] = useState("Designation");
  const location = useLocation();
  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={2} >
          <SettingsMenu
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>
        <Grid xs={10}>
          <Paper
            sx={{
              boxShadow: 3,
            }}
          >
            {location.pathname === "/admin/master/designation" ? (
              <Designation />
            ) : null}
            {location.pathname === "/admin/master/employment/type" ? (
              <EmploymentType />
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
