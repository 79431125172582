import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const UserToken = createSlice({
  name: "token",
  initialState: {
    token: localStorage.getItem("token"),
    userDetails: {
      id: "",
      first_name: "",
      username: "",
      last_name: "",
      email: "",
      role: "",
      firmName: "",
      phonenumber: "",
      approver: "",
      teamlead: "",
      approver_id: "",
      image_url: "",
      department_id: "",
      department_name: "",
    },
    globalSearchValue: "",
    currentLocation:"",
    currentSubMenu:"",
    // currentLocation: localStorage.getItem("currentScreen")
    //   ? localStorage.getItem("currentScreen")
    //   : "Team Zone",
    // currentSubMenu: localStorage.getItem("currentSubMenu")
    //   ? localStorage.getItem("currentSubMenu")
    //   : "Attendance",
    adminCompanyId: localStorage.getItem("adminCompanyId")
      ? JSON.parse(localStorage.getItem("adminCompanyId"))
      : 99999,
    adminCompanyName: localStorage.getItem("adminCompanyname")
      ? localStorage.getItem("adminCompanyname")
      : "All Companies",
    departmentViewMethod: "CardView",
    productAccess: JSON.parse(localStorage.getItem("productAccess"))
      ? JSON.parse(localStorage.getItem("productAccess"))
      : null,
    startDate: moment(new Date()).subtract(7, "d").format("DD/MM/YYYY"),
    endDate: moment(new Date()).format("DD/MM/YYYY"),
    domain:
      window.location.hostname === "localhost"
        ? `https://tom.organflow.co/api/`
        : `https://${window.location.hostname}/api/`,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.userDetails = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
    setCurrentSubMenu: (state, action) => {
      state.currentSubMenu = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setReduxStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setReduxEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setGlobalSearchValue: (state, action) => {
      state.globalSearchValue = action.payload;
    },
    setDepartmentViewMethod: (state, action) => {
      state.departmentViewMethod = action.payload;
    },
    setAdminCompanyId: (state, action) => {
      state.adminCompanyId = action.payload;
    },
    setAdminCompanyName: (state, action) => {
      state.adminCompanyName = action.payload;
    },
    setProductAccess: (state, action) => {
      state.productAccess = action.payload;
    },
  },
});

export const {
  setToken,
  setCurrentUser,
  setCurrentLocation,
  setDateRange,
  setReduxStartDate,
  setReduxEndDate,
  setDomain,
  setGlobalSearchValue,
  setCurrentSubMenu,
  setDepartmentViewMethod,
  setAdminCompanyId,
  setAdminCompanyName,
  setProductAccess,
} = UserToken.actions;

export default UserToken.reducer;
